import { Messages } from '../types/localization';

const ru: Messages = {
    'questionnaire.call': 'Звонок',
    'questionnaire.directions': 'Направления',
    'questionnaire.welcome.title': 'Добро пожаловать в онлайн-регистрацию пациента',
    'questionnaire.welcome.lead.hospital':
      '{hospitalName} нуждается в вашей медицинской информации и истории болезни, чтобы предоставить вам наилучший уход. Пожалуйста, заполните этот онлайн-опросник. Просто, безопасно и конфиденциально.',
    'questionnaire.welcome.lead.generic':
      'Мы нуждаемся в вашей медицинской информации и истории болезни, чтобы предоставить вам наилучший уход. Пожалуйста, заполните этот онлайн-опросник. Просто, безопасно и конфиденциально.',
    'questionnaire.welcome.instructions.request': 'Пожалуйста, убедитесь, что у вас есть следующая информация:',
    'questionnaire.welcome.instructions.prescription': 'Названия и дозировки рецептурных лекарств',
    'questionnaire.welcome.instructions.over-the-counter': 'Названия и дозировки безрецептурных лекарств',
    'questionnaire.welcome.instructions.allergies': 'Аллергии и их реакции',
    'questionnaire.welcome.instructions.surgeries': 'Предыдущие операции и годы их проведения',
    'questionnaire.welcome.call': 'После получения вашей информации вы можете получить звонок от медицинского персонала для уточнения некоторых вопросов.',
    'questionnaire.welcome.end': 'Мы надеемся предоставить вам наилучшее медицинское обслуживание и сервис',
    'questionnaire.welcome.continue': 'Продолжить',
    'questionnaire.tabs.questionnaire': 'Опросник',
    'questionnaire.tabs.docs': 'Загрузить',
    'questionnaire.tabs.signature': 'Подпись',
    'questionnaire.error.submission': 'Ошибка при отправке формы. Пожалуйста, повторите попытку. Если проблема сохраняется, обратитесь в службу поддержки.',
    'questionnaire.sections.annex.confirmation.title': 'Подтверждение действия',
    'questionnaire.sections.annex.confirmation.text':
      'Вы уже подписали предварительные медицинские формы. Изменение опросника приведет к необходимости их удаления. Вы хотите редактировать?',
    'questionnaire.sections.back': 'Назад',
    'questionnaire.sections.save.finish': 'Сохранить и завершить',
    'questionnaire.sections.save.continue': 'Сохранить и продолжить',
    'questionnaire.list.add': 'Добавить',
    'questionnaire.list.remove': 'Удалить',
    'questionnaire.text.limit.used': '{remaining, plural, =1 {остался 1 символ} other {осталось # символов}}',
    'questionnaire.text.limit.unused': 'Максимальная длина: {limit, plural, =1 {1 символ} other {# символов}}',
    'questionnaire.dob.title': 'Дата рождения',
    'questionnaire.dob.placeholder.day': 'День',
    'questionnaire.dob.placeholder.month': 'Месяц',
    'questionnaire.dob.placeholder.year': 'Год',
    'questionnaire.dob.day': 'День',
    'questionnaire.dob.month': 'Месяц',
    'questionnaire.dob.year': 'Год',
    'questionnaire.date.placeholder.day': 'День',
    'questionnaire.date.placeholder.month': 'Месяц',
    'questionnaire.date.placeholder.year': 'Год',
    'questionnaire.date.day': 'День',
    'questionnaire.date.month': 'Месяц',
    'questionnaire.date.year': 'Год',
    'questionnaire.bmi.height': 'Рост',
    'questionnaire.bmi.feet': 'Футы',
    'questionnaire.bmi.inches': 'Дюймы',
    'questionnaire.bmi.weight': 'Вес',
    'questionnaire.bmi.pounds': 'Фунты',
    'questionnaire.csz.city': 'Город',
    'questionnaire.csz.state': 'Штат',
    'questionnaire.csz.zip': 'Почтовый индекс',
    'questionnaire.yesno.yes': 'Да',
    'questionnaire.yesno.no': 'Нет',
    'questionnaire.oneof.choose': 'Выберите один вариант',
    'questionnaire.medication.label': 'Название',
    'questionnaire.medication.placeholder': 'Начните ввод для получения предложений',
    'questionnaire.checkboxes.question': 'Выберите все применимые варианты',
    'questionnaire.checkboxes.none': 'Нет',
    'questionnaire.success.call': 'Звонок',
    'questionnaire.success.directions': 'Адрес',
    'questionnaire.success.thanks': 'Спасибо!',
    'questionnaire.success.docs.uploaded': 'Файлы загружены.',
    'questionnaire.success.signature.submitted': 'Подпись отправлена.',
    'questionnaire.success.questionnaire.submitted': 'Опросник успешно отправлен {progress}%',
    'questionnaire.success.staff.call': 'Опросник успешно отправлен. С вами может связаться сотрудник или медсестра для получения дополнительной информации.',
    'questionnaire.success.back': 'Вернуться к форме',
    'questionnaire.success.close': 'Закрыть',
    'questionnaire.docs.title': 'Загрузка файлов',
    'questionnaire.docs.empty': 'Здесь нет файлов.',
    'questionnaire.docs.delete': 'Удалить',
    'questionnaire.docs.confirm': 'Подтверждение действия',
    'questionnaire.docs.sure': 'Вы уверены, что хотите удалить этот файл?',
    'questionnaire.docs.finish': 'Завершить',
    'questionnaire.signature.title': 'Электронная подпись',
    'questionnaire.signature.request': 'Пожалуйста, прочитайте электронный документ',
    'questionnaire.signature.instruction': 'Подпишите здесь',
    'questionnaire.signature.preview': 'Предварительный просмотр',
    'questionnaire.signature.agreement': 'Я соглашаюсь использовать электронные записи и подписи',
    'questionnaire.signature.clear': 'Очистить данные',
    'questionnaire.signature.back': 'Назад',
    'questionnaire.signature.finish': 'Завершить',
    'questionnaire.signature.save': 'Сохранить и завершить',
    'homepage.instructions': 'Введите ваш PIN-код',
    'homepage.error.pin': 'Недействительный PIN-код, попробуйте снова.',
    'homepage.error.unexpected': 'Произошла неожиданная ошибка, пожалуйста, повторите попытку позже.',
    'homepage.helper': 'Вы можете найти PIN-код в SMS-сообщении от хирургического центра.',
    'status.patient': 'Пациент',
    'status.physician': 'Врач',
    'status.status': 'Статус',
    'status.time': 'Время',
    'status.admitted': 'Госпитализирован',
    'status.preparing-for-surgery': 'Подготовка к операции',
    'status.in-surgery': 'В процессе операции',
    'status.in-recovery': 'На восстановлении',
    'status.in-postop': 'Послеоперационный период',
    'status.able-to-visit': 'Доступен для посещения',
    'status.final.title': 'Посещение этого пациента завершено',
    'status.final.subtitle': 'Посещение этого пациента завершено',
  
    'hospital.call': 'Пожалуйста, позвоните',
    'hospital.directions': 'Получить направления',
    'feedback.error': 'Произошла ошибка при отправке формы, пожалуйста, повторите попытку. Если проблема сохраняется, обратитесь в службу поддержки.',
    'feedback.share.question': 'Хотите поделиться своим опытом с другими?',
    'feedback.share.google': 'Оставить отзыв на Google',
    'feedback.share.yelp': 'Оценить нас на Yelp',
    'feedback.share.skip': 'Пропустить',
    'feedback.suggestion.question': 'Хотите оставить отзыв или рассказать, как улучшить ваш опыт?',
    'feedback.send': 'Отправить отзыв',
    'feedback.success.title': 'Большое спасибо!',
    'feedback.success.text': 'Ваши предложения отправлены.',
    'rating.instructions': 'Пожалуйста, оцените ваш опыт',
    'rating.thanks': 'Большое спасибо!',
    'This field is required': 'Это поле обязательно для заполнения',
    'Please fill in the required fields': 'Пожалуйста, заполните обязательные поля'
  }  

export default ru;