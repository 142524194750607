import React from 'react';
import styled from 'styled-components';

const HtmlButton = styled.button`
  background: ${({ theme }) => theme.rateButtonColor};
  border: 1px solid ${({ theme }) => theme.rateButtonColor};
  border-radius: 4px;
  font-family: Rubik, -apple-system, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  width: 100%;
  font-size: 1.8em;
  color: #fff;
  padding: 0.5em;
  text-align: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: 160ms all;
  &:hover {
    background: ${({ theme }) => theme.rateButtonHoverColor};
    border: 1px solid ${({ theme }) => theme.rateButtonHoverColor};
  }
  &:disabled {
    opacity: ${({ disabled }) => disabled} && 0.7;
  }
`;

const ButtonThemeSupport = ({ label, onClick, disabled, type, theme }) => {
  console.log(theme);
  return (
    <HtmlButton onClick={onClick} disabled={disabled} type={type}>
      {label}
    </HtmlButton>
  );
};

export default ButtonThemeSupport;
