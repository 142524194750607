import {ProcedureContextType} from "../ProcedureContext";

interface Expression<T> {
  evaluate(context: unknown): T;
}

type LiteralValue = string | number | string[];

interface Literal extends Expression<LiteralValue> {
  type: "literal";
  value: LiteralValue;
}

interface Context extends Expression<any> {
  type: "context";
  key: string;
}

interface Eq extends Expression<boolean> {
  type: "eq";
  left: Context;
  right: Literal;
}

interface NotEq extends Expression<boolean> {
  type: "notEq";
  left: Context;
  right: Literal;
}

interface Lt extends Expression<boolean> {
  type: "lt";
  left: Context;
  right: Literal;
}

interface Lte extends Expression<boolean> {
  type: "lte";
  left: Context;
  right: Literal;
}

interface Gt extends Expression<boolean> {
  type: "gt";
  left: Context;
  right: Literal;
}

interface Gte extends Expression<boolean> {
  type: "gte";
  left: Context;
  right: Literal;
}

interface In extends Expression<boolean> {
  type: "in";
  left: Context;
  right: Literal;
}

interface NotIn extends Expression<boolean> {
  type: "notIn";
  left: Context;
  right: Literal;
}

interface And extends Expression<boolean> {
  type: "and";
  left: Expression<boolean>;
  right: Expression<boolean>;
}

interface Or extends Expression<boolean> {
  type: "or";
  left: Expression<boolean>;
  right: Expression<boolean>;
}

export type Condition = In | NotIn | Eq | NotEq | Lt | Gt | Lte | Gte | Or | And;

export class LiteralExpression implements Literal {
  type = "literal" as const;
  value: LiteralValue;

  constructor(value: LiteralValue) {
    this.value = value;
  }

  evaluate(): LiteralValue {
    return this.value;
  }
}

export class ContextExpression implements Context {
  type = "context" as const;
  key: string;

  constructor(key: string) {
    this.key = key;
  }

  evaluate(context: Record<string, unknown>): any {
    return context[this.key];
  }
}

export class EqExpression implements Eq {
  type = "eq" as const;
  left: ContextExpression;
  right: LiteralExpression;

  constructor(left: ContextExpression, right: LiteralExpression) {
    this.left = left;
    this.right = right;
  }

  evaluate(context: Record<string, unknown>): boolean {
    return this.left.evaluate(context) === this.right.evaluate();
  }
}

export class NotEqExpression implements NotEq {
  type = "notEq" as const;
  left: ContextExpression;
  right: LiteralExpression;

  constructor(left: ContextExpression, right: LiteralExpression) {
    this.left = left;
    this.right = right;
  }

  evaluate(context: Record<string, unknown>): boolean {
    return this.left.evaluate(context) !== this.right.evaluate();
  }
}

export class LtExpression implements Lt {
  type = "lt" as const;
  left: ContextExpression;
  right: LiteralExpression;

  constructor(left: ContextExpression, right: LiteralExpression) {
    this.left = left;
    this.right = right;
  }

  evaluate(context: Record<string, unknown>): boolean {
    return this.left.evaluate(context) < this.right.evaluate();
  }
}

export class LteExpression implements Lte {
  type = "lte" as const;
  left: ContextExpression;
  right: LiteralExpression;

  constructor(left: ContextExpression, right: LiteralExpression) {
    this.left = left;
    this.right = right;
  }

  evaluate(context: Record<string, unknown>): boolean {
    return this.left.evaluate(context) <= this.right.evaluate();
  }
}

export class GtExpression implements Gt {
  type = "gt" as const;
  left: ContextExpression;
  right: LiteralExpression;

  constructor(left: ContextExpression, right: LiteralExpression) {
    this.left = left;
    this.right = right;
  }

  evaluate(context: Record<string, unknown>): boolean {
    return this.left.evaluate(context) > this.right.evaluate();
  }
}

export class GteExpression implements Gte {
  type = "gte" as const;
  left: ContextExpression;
  right: LiteralExpression;

  constructor(left: ContextExpression, right: LiteralExpression) {
    this.left = left;
    this.right = right;
  }

  evaluate(context: Record<string, unknown>): boolean {
    return this.left.evaluate(context) >= this.right.evaluate();
  }
}

export class InExpression implements In {
  type = "in" as const;
  left: ContextExpression;
  right: LiteralExpression;

  constructor(left: ContextExpression, right: LiteralExpression) {
    this.left = left;
    this.right = right;
  }

  evaluate(context: Record<string, unknown>): boolean {
    return (this.right.evaluate() as string[]).includes(this.left.evaluate(context));
  }
}

export class NotInExpression implements NotIn {
  type = "notIn" as const;
  left: ContextExpression;
  right: LiteralExpression;

  constructor(left: ContextExpression, right: LiteralExpression) {
    this.left = left;
    this.right = right;
  }

  evaluate(context: Record<string, unknown>): boolean {
    return !((this.right.evaluate() as string[]).includes(this.left.evaluate(context)));
  }
}

export class AndExpression implements And {
  type = "and" as const;
  left: Expression<boolean>;
  right: Expression<boolean>;

  constructor(left: Expression<boolean>, right: Expression<boolean>) {
    this.left = left;
    this.right = right;
  }

  evaluate(context: Record<string, unknown>): boolean {
    return this.left.evaluate(context) && this.right.evaluate(context);
  }
}

export class OrExpression implements Or {
  type = "or" as const;
  left: Expression<boolean>;
  right: Expression<boolean>;

  constructor(left: Expression<boolean>, right: Expression<boolean>) {
    this.left = left;
    this.right = right;
  }

  evaluate(context: Record<string, unknown>): boolean {
    return this.left.evaluate(context) || this.right.evaluate(context);
  }
}

export function createExpression(json: any): Expression<any> {
  switch (json.type) {
    case "literal":
      return new LiteralExpression(json.value);
    case "context":
      return new ContextExpression(json.key);
    case "eq":
      return new EqExpression(createExpression(json.left) as ContextExpression, createExpression(json.right) as LiteralExpression);
    case "notEq":
      return new NotEqExpression(createExpression(json.left) as ContextExpression, createExpression(json.right) as LiteralExpression);
    case "and":
      return new AndExpression(createExpression(json.left) as Expression<boolean>, createExpression(json.right) as Expression<boolean>);
    case "or":
      return new OrExpression(createExpression(json.left) as Expression<boolean>, createExpression(json.right) as Expression<boolean>);
    case "lt":
      return new LtExpression(createExpression(json.left) as ContextExpression, createExpression(json.right) as LiteralExpression);
    case "lte":
      return new LteExpression(createExpression(json.left) as ContextExpression, createExpression(json.right) as LiteralExpression);
    case "gt":
      return new GtExpression(createExpression(json.left) as ContextExpression, createExpression(json.right) as LiteralExpression);
    case "gte":
      return new GteExpression(createExpression(json.left) as ContextExpression, createExpression(json.right) as LiteralExpression);
    case "in":
      return new InExpression(createExpression(json.left) as ContextExpression, createExpression(json.right) as LiteralExpression);
    case "notIn":
      return new NotInExpression(createExpression(json.left) as ContextExpression, createExpression(json.right) as LiteralExpression);
    default:
      throw new Error(`Unknown expression type: ${json.type}`);
  }
}

/**
 * Examples for parameters:
 *
 * const json = {
 *  "type": "eq",
 *  "left": {
 *    "type": "context",
 *    "key": "anesthesiaType"
 *  },
 *  "right": {
 *    "type": "literal",
 *    "value": "General"
 *  }
 * };
 *
 * const context: ProcedureContextType  = {
 *  anesthesiaType: "General"
 * };
 */

export const shouldRenderSection = (procedureContext?: ProcedureContextType, condition?: any) => {
  const expression = createExpression(condition);
  const result = expression.evaluate(procedureContext);

  return result;
}

export const filterSections = (procedureContext?: ProcedureContextType, allSections?: any[]): any[] =>
  (allSections || [])
    .filter(
      section => section[1]?.condition ?
        shouldRenderSection(procedureContext, section[1]?.condition)
        : true
    );

export const getQuestionnaireProgress = (procedureContext?: ProcedureContextType, allSections?: any[]): number =>
  filterSections(procedureContext, allSections)
    .map(section => section?.[1]?.body?.weight)
    .reduce((acc, curr) => acc + curr, 0);

