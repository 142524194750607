import React, { createContext, ReactNode, useCallback, useContext, useEffect, useRef } from 'react';

/** Wraps `fnToWrap` with validation. Skips `fnToWrap` if validation fails. */
type ValidationWrapper = (fnToWrap: () => void, onValidationFail?: () => void) => () => void;

interface SectionContextProps {
  children: (validationWrapper: ValidationWrapper) => ReactNode;
}

type ValidationFunction = () => boolean;

type ValidationRegistrar = (validationFn: ValidationFunction) => () => void;

const sectionContext = createContext<ValidationRegistrar>(() => () => {});

export function useSectionContextValidation(validationFn: ValidationFunction): void {
  const register = useContext(sectionContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => register(validationFn), [register]);
}

export default function SectionContext({ children }: SectionContextProps) {
  const validationsRef = useRef<ValidationFunction[]>([]);

  const register = useCallback(
    (validationFn: ValidationFunction) => {
      validationsRef.current.push(validationFn);

      return () => {
        validationsRef.current = validationsRef.current.filter(fn => fn !== validationFn);
      };
    },
    [validationsRef]
  );

  const validationWrapper = useCallback(
    (fnToWrap, onValidationFail) => () => {
      const results = validationsRef.current.map(validation => validation());

      if (results.every(result => result)) {
        fnToWrap();
      } else {
        onValidationFail?.();
      }
    },
    [validationsRef]
  );

  return <sectionContext.Provider value={register}>{children(validationWrapper)}</sectionContext.Provider>;
}
