import React, { useState } from 'react';
import get from 'lodash/get';
import { getGMapsLink } from '../sections/PatientStatus/HospitalInformation';
import {
  Action,
  Actions,
  Disclaimer,
  H1,
  H2,
  Header,
  Paragraph,
} from '../sections/Questionnaire/QuestionnaireStartPage';
import { Box, Container, Icon, Typography } from '@material-ui/core';
import { ClipLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalStyles } from './questionnaire/Questionnaire';
import TextBox from '../components/TextBox';
import Spacer from '../components/Spacer';
import ButtonThemeSupport from '../components/ButtonThemeSupport';
import { lightTheme } from '../components/theme/Themes';
import { ThemeProvider } from 'styled-components';
import { useMutation, useQuery } from 'react-apollo-hooks';
import { hospital as hospitalQuery, patient as patientQuery } from '../graphql/schema/familyMember';
import { caretakerConfirmation, getNotificationTemplate } from '../graphql/schema/patientPickup';
import { FormattedMessage } from 'react-intl';
import isMiddleEast from '../utils/isMiddleEast';

const useStyles = makeStyles(() => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const PatientPickup = () => {
  const classes = useStyles();
  const [caretakerAnswer, setCaretakerAnswer] = useState('');

  const { data: hospitalData, loading: hospitalLoading } = useQuery(hospitalQuery);
  const { data: messageData, loading: messageLoading } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'FamilyReady', channel: 'SMS', destination: 'Caretaker' },
  });
  const { data: patientData, loading: patientLoading } = useQuery(patientQuery);
  const confirm = useMutation(caretakerConfirmation);

  const loading = hospitalLoading || patientLoading || messageLoading;
  const hospital = get(hospitalData, 'patientHospital', {});
  const patient = get(patientData, 'patientForCaretaker', {});
  const additionalInfo = get(messageData, 'getNotificationTemplate[0].additionalInfo', '')
    .replace(/{{patientFullName}}/g, patient?.name || '')
    .replace(/{{patientInitials}}/g, patient?.initials || '');

  const handleConfirm = async () => {
    await confirm({
      variables: {
        content: caretakerAnswer,
      },
      refetchQueries: [{ query: patientQuery }],
    });
  };

  if (loading) {
    return (
      <ThemeProvider theme={lightTheme}>
        <Container
          maxWidth="sm"
          className={classes.container}
          style={{ justifyContent: 'center', alignItems: 'center' }}
        >
          <ClipLoader />
        </Container>
      </ThemeProvider>
    );
  }

  return get(patient, 'caretakerMessages', false) ? (
    <div className="Page Disclaimer">
      <GlobalStyles />
      <Header>
        {get(hospital, 'name', false) && <H2>{get(hospital, 'name')}</H2>}
        <Actions>
          {get(hospital, 'contact', false) && (
            <Action href={`tel:${get(hospital, 'contact')}`}>
              <span className="material-icons">phone</span>
              <FormattedMessage id="hospital.call" defaultMessage="Call" />
            </Action>
          )}
          {get(hospital, 'address', false) && (
            <Action href={getGMapsLink(get(hospital, 'address'))} target="_blank" rel="noopener noreferrer">
              <span className="material-icons">directions</span>
              <FormattedMessage id="hospital.directions" defaultMessage="Directions" />
            </Action>
          )}
        </Actions>
      </Header>

      <Spacer size={8} />
      <Box my="auto" style={{ textAlign: 'center' }} color="palette.success.main">
        <Typography gutterBottom variant="h5">
          <FormattedMessage id="caretaker.thanks" defaultMessage="Thank you!" />
        </Typography>
        <Typography gutterBottom color="textSecondary">
          <FormattedMessage id="caretaker.successfully-sent" defaultMessage="Your message was sent successfully." />
        </Typography>
        <Icon fontSize="large">check</Icon>
      </Box>
    </div>
  ) : (
    <ThemeProvider theme={lightTheme}>
      <Box>
        <div className="Page Disclaimer">
          <GlobalStyles />
          <Header>
            {get(hospital, 'name', false) && <H2>{get(hospital, 'name')}</H2>}
            <Actions>
              {get(hospital, 'contact', false) && (
                <Action href={`tel:${get(hospital, 'contact')}`}>
                  <span className="material-icons">phone</span>
                  <FormattedMessage id="hospital.call" defaultMessage="Call" />
                </Action>
              )}
              {get(hospital, 'address', false) && (
                <Action href={getGMapsLink(get(hospital, 'address'))} target="_blank" rel="noopener noreferrer">
                  <span className="material-icons">directions</span>
                  <FormattedMessage id="hospital.directions" defaultMessage="Directions" />
                </Action>
              )}
            </Actions>
            <H1 style={{ textAlign: 'center' }}>
              <FormattedMessage id="caretaker.ready-to-pick-up" defaultMessage="Patient is ready to be picked up!" />
            </H1>
          </Header>

          <Disclaimer>
            <Paragraph lead>{additionalInfo}</Paragraph>
          </Disclaimer>

          <div style={{ paddingBottom: 16 }}>
            <TextBox
              name="caretakerAnswer"
              label=""
              value={caretakerAnswer}
              onChange={e => setCaretakerAnswer(e.target.value)}
              hospitalId={isMiddleEast(hospital?.id) ? 'Type here... in arabic' : 'Type here...'}
            />
            <Spacer size={3} />

            <ButtonThemeSupport
              disabled={false}
              label={<FormattedMessage id="caretaker.confirm" defaultMessage="Confirm" />}
              onClick={handleConfirm}
            />
          </div>
        </div>
      </Box>
    </ThemeProvider>
  );
};

export default PatientPickup;
