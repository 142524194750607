import React from 'react';
import { Avatar } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

const PatientIcon = ({ icon, backgroundColor, inline }) => {
  const classes = useStyles();

  return (
    <Avatar variant="rounded" style={{ backgroundColor: backgroundColor }} className={classes.small}>
      <Icon>{icon}</Icon>
    </Avatar>
  );
};

export default PatientIcon;
