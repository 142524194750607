export enum QuestionnaireType {
  PreOp = 'PreOp',
  PreOpChart = 'PreOpChart',
  OrChart = 'OrChart',
  PacuChart = 'PacuChart',
  Intake = 'InTakeForm',
}

export enum FormType {
  PreOpForm = 'PRE_OP_FORM',
  InTakeForm = 'IN_TAKE_FORM',
  PreOpChartForm = 'PREOP_CHART_FORM',
  OrChartForm = 'OR_CHART_FORM',
  PacuChartForm = 'PACU_CHART_FORM',
}
