import gql from 'graphql-tag';

const openPosition = gql`
  fragment OpenPositionData on StaffShiftOpenPosition {
    id
    staffHospitalId
    staff {
      id
      name
      primarySpecialty
      otherSpecialties
      title
    }
    date
    from
    to
    description {
      primarySpecialty
      otherSpecialties
      title
      isProcedure
      isRoom
      description
    }
    staffShiftId
    staffShiftHospitalId
    staffShiftHospitalName
    state
    logs {
      type
      state
      userId
      userHospitalId
      userName
      userPhoneNumber
      at
    }
  }
`;

export const fragments = {
  openPosition,
};

export const getOpenPositions = gql`
  query staffMemberOpenPosition {
    staffMemberOpenPosition {
      ...OpenPositionData
    }
  }
  ${fragments.openPosition}
`;

export const staffMemberApprove = gql`
  mutation staffMemberApprove {
    staffMemberApprove
  }
`;

export const staffMemberReject = gql`
  mutation staffMemberReject {
    staffMemberReject
  }
`;
