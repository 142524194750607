import React from 'react';
import gql from "graphql-tag";
import {useQuery} from "react-apollo-hooks";
import {ProcedureContext, ProcedureContextType} from "./ProcedureContext";

const GET_PROCEDURE_CONTEXT = gql`
  query nurseProcedureContext($questionnaireExchangeId: Long!) {
    nurseProcedureContext(questionnaireExchangeId: $questionnaireExchangeId) {
      anesthesiaType
      patientAge
      patientSex
      physicianSpecialties
      physicianCategories
    }
  }
`;

export const NurseProcedureContextProvider = ({ children, questionnaireExchangeId }: { children: React.ReactNode; questionnaireExchangeId: string; }) => {
  const { data } = useQuery(GET_PROCEDURE_CONTEXT, {
    variables: {
      questionnaireExchangeId: parseInt(questionnaireExchangeId, 10)
    },
    skip: !questionnaireExchangeId,
  });

  const procedureContext: ProcedureContextType = {
    anesthesiaType: data?.nurseProcedureContext?.anesthesiaType,
    patientAge: data?.nurseProcedureContext?.patientAge,
    patientSex: data?.nurseProcedureContext?.patientSex,
    physicianSpecialties: data?.nurseProcedureContext?.physicianSpecialties,
    physicianCategories: data?.nurseProcedureContext?.physicianCategories,
  };

  return (
    <ProcedureContext.Provider value={procedureContext}>
      {children}
    </ProcedureContext.Provider>
  );
};
