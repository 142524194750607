import StaffMember, { OtherSpecialty, PrimarySpecialty, Title } from './StaffMember';

export interface OpenPositionDescription {
  primarySpecialty?: PrimarySpecialty | null;
  otherSpecialties?: OtherSpecialty[] | null;
  title?: Title | null;
  description?: string | null;
  isProcedure?: boolean;
  isRoom?: boolean;
}

export enum StaffShiftState {
  CreatedOpenPosition = 'CREATED_OPEN_POSITION',
  BookOpenPosition = 'BOOK_OPEN_POSITION',
  ApprovedByHiringManager = 'APPROVED_BY_HIRING_MANAGER',
  ApprovedByStaffMember = 'APPROVED_BY_STAFF_MEMBER',
  RejectedByStaffMember = 'REJECTED_BY_STAFF_MEMBER',
  Assigned = 'ASSIGNED',
  Opened = 'OPENED',
}

export interface StaffShiftLog {
  type: string;
  state: StaffShiftState;
  userId: number;
  userHospitalId: number;
  userName: string;
  userPhoneNumber: string;
  at: string;
}

export interface OpenPosition {
  id: number;
  staff?: StaffMember;
  date?: string;
  from?: string;
  to?: string;
  note?: string;
  description?: OpenPositionDescription;
  staffShiftId: number;
  staffShiftHospitalId?: number;
  staffShiftHospitalName?: string;
  state: StaffShiftState;
  logs: StaffShiftLog[];
}
