import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Context, Locale, LocaleId } from '../types/localization';
import en from '../messages/en';
import es from '../messages/es';
import ar from '../messages/ar';
import kr from '../messages/kr';
import ch from '../messages/ch';
import fa from '../messages/fa';
import tl from '../messages/tl';
import ru from '../messages/ru';

export const enLocale = { id: LocaleId.fromString('en_US'), name: 'English', nativeName: 'English', messages: en };
export const esLocale = { id: LocaleId.fromString('es_MX'), name: 'Spanish', nativeName: 'Español', messages: es };
export const arLocale = { id: LocaleId.fromString('ar_SA'), name: 'Arabic', nativeName: 'العربية', messages: ar };
export const krLocale = { id: LocaleId.fromString('ko_KR'), name: 'Korean', nativeName: '한국인', messages: kr };
export const chLocale = { id: LocaleId.fromString('zh_CN'), name: 'Chinese', nativeName: '中文', messages: ch };
export const faLocale = { id: LocaleId.fromString('fa'), name: 'Farsi', nativeName: 'فارسی', messages: fa };
export const tlLocale = { id: LocaleId.fromString('tl'), name: 'Tagalog', nativeName: 'tagalog', messages: tl };
export const ruLocale = { id: LocaleId.fromString('ru_RU'), name: 'Russian', nativeName: 'русский', messages: ru };

export const locales: Locale[] = [enLocale, esLocale, arLocale, krLocale, chLocale, faLocale, tlLocale, ruLocale];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const browserSupportedLocales: Locale[] = locales.filter(
  locale => navigator.languages.indexOf(locale.id.toString()) >= 0
);

export const defaultValue: Context = {
  availableLocales: locales,
  selectedLocale: locales[0],
  selectLocale: () => {},
  setSpecificLocales: () => {},
};

export const LocaleContext = React.createContext<Context>(defaultValue);

export const useSpecificLocales = (locales: Array<Pick<Locale, 'id' | 'messages'>>) => {
  const { setSpecificLocales } = useContext(LocaleContext);

  useEffect(() => {
    setSpecificLocales(locales);
    return () => setSpecificLocales(undefined);
  }, [locales, setSpecificLocales]);
};

interface LocaleProviderProps {
  locales: Locale[];
  children: React.ReactNode;
}

export const LocaleProvder = ({ locales, children }: LocaleProviderProps) => {
  const [specificLocales, setSpecificLocales] = useState<Array<Pick<Locale, 'id' | 'messages'>> | undefined>();

  const availableLocales = useMemo(() => {
    if (specificLocales) {
      return locales.flatMap(locale => {
        const specificLocale = specificLocales.find(specificLocale => specificLocale.id.equals(locale.id));

        if (specificLocale) {
          return [
            {
              ...locale,
              messages: {
                ...(locale.messages || {}),
                ...(specificLocale.messages || {}),
              },
            },
          ];
        }

        return [];
      });
    } else {
      return locales;
    }
  }, [locales, specificLocales]);

  const defaultLocaleId = useMemo(() => {
    const localeId = LocaleId.fromString(localStorage.getItem('locale') || navigator.language);
    return localeId || (availableLocales[0] || {}).id || locales[0].id;
  }, [locales, availableLocales]);

  const [selectedLocaleId, setSelectedLocaleId] = useState(defaultLocaleId);

  const selectedLocale = useMemo(
    () =>
      availableLocales.find(locale => locale.id.equals(selectedLocaleId)) ||
      availableLocales.find(locale => locale.id.equals(defaultLocaleId)) ||
      availableLocales.find(locale => locale.id.language === selectedLocaleId.language) ||
      availableLocales.find(locale => locale.id.language === defaultLocaleId.language) ||
      availableLocales[0] ||
      locales[0],
    [locales, availableLocales, defaultLocaleId, selectedLocaleId]
  );

  useEffect(() => {
    if (!availableLocales.find(locale => locale.id.equals(selectedLocaleId))) {
      setSelectedLocaleId(defaultLocaleId);
    }
  }, [availableLocales, defaultLocaleId, selectedLocaleId]);

  const selectLocale = useCallback(
    (localeId: LocaleId) => {
      if (availableLocales.find(locale => locale.id.equals(localeId))) {
        localStorage.setItem('locale', localeId.toString());
        setSelectedLocaleId(localeId);
      }
    },
    [availableLocales]
  );

  const context: Context = useMemo(
    () => ({
      availableLocales,
      selectedLocale,
      selectLocale,
      setSpecificLocales,
    }),
    [availableLocales, selectedLocale, selectLocale]
  );

  return <LocaleContext.Provider value={context}>{children}</LocaleContext.Provider>;
};

export default LocaleProvder;
