import React, { Fragment } from 'react';
import { Box, Button, Container, fade, Icon, Paper, Typography, Zoom } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { myConsultation, setConsultationStatus } from '../graphql/schema/consultation';
import { useMutation, useQuery } from 'react-apollo-hooks';
import { hospital as hospitalQuery } from '../graphql/schema/familyMember';
import get from 'lodash/get';
import { format } from 'date-fns';
import ConsultationStatus from '../enums/ConsultationStatus';
import { ClipLoader } from 'react-spinners';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  status: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  statusConfirmed: {
    backgroundColor: fade(theme.palette.success.main, 0.1),
    color: theme.palette.success.main,
  },
  statusReschedule: {
    backgroundColor: fade(theme.palette.info.main, 0.1),
    color: theme.palette.info.main,
  },
  actions: {
    '& > *': {
      marginTop: theme.spacing(1),
    },
  },
  root: {
    height: 180,
  },
  paper: {
    margin: theme.spacing(1),
  },
  svg: {
    width: 100,
    height: 100,
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
}));

const StatusCard = ({ confirmed, reschedule, canceled, hospitalName, consultationDate, consultationTime }) => {
  const classes = useStyles();

  return (
    <Paper
      elevation={0}
      className={clsx(classes.status, {
        [classes.statusConfirmed]: confirmed,
        [classes.statusReschedule]: reschedule,
      })}
    >
      <Box p={2} textAlign="center" display="flex" flexDirection="column" alignItems="center">
        <Box display="flex" alignItems="center" mb={1}>
          <Box style={{ fontSize: 0 }} mr={1}>
            {confirmed && <Icon>check</Icon>}
            {reschedule && <Icon>close</Icon>}
            {canceled && <Icon>close</Icon>}
            {!confirmed && !reschedule && !canceled && <Icon style={{ opacity: 0.45 }}>info</Icon>}
          </Box>
          <Typography color="inherit" variant="subtitle2">
            {confirmed && 'Confirmed'}
            {reschedule && 'Reschedule'}
            {canceled && 'Canceled'}
            {!confirmed && !reschedule && !canceled && 'Schedule for'}
          </Typography>
        </Box>
        <Typography color="textPrimary" variant="h6">
          {hospitalName}
        </Typography>
        <Typography color="textPrimary" variant="h5" style={{ textDecoration: reschedule ? 'line-through' : 'none' }}>
          <Box fontWeight="fontWeightMedium" display="inline">
            {format(consultationDate, 'DD MMMM, YYYY')}
          </Box>{' '}
          at{' '}
          <Box fontWeight="fontWeightMedium" display="inline">
            {consultationTime}
          </Box>
        </Typography>
      </Box>
    </Paper>
  );
};

const Confirmation = () => {
  const classes = useStyles();

  const { data: consultationData, loading } = useQuery(myConsultation);
  const { data: hospitalData } = useQuery(hospitalQuery);

  const consultation = consultationData.myConsultation;
  const hospital = hospitalData.patientHospital;
  const hospitalName = get(hospital, 'name', '');
  const consultationDate = get(consultation, 'consultationDate');
  const consultationTime = get(consultation, 'consultationTime');
  const status = get(consultation, 'status');
  const changeConsultationStatus = useMutation(setConsultationStatus);

  const consultationStateConfirmed = [
    ConsultationStatus.ConsultationConfirmed,
    ConsultationStatus.ConsultationCanceled,
    ConsultationStatus.ConsultationNeedsReschedule,
  ].includes(status);

  const handleStatus = status => {
    changeConsultationStatus({
      variables: { status },
      refetchQueries: [{ query: myConsultation }],
    }).catch(e => {
      console.log(e);
    });
  };

  const handleConfirm = () => {
    handleStatus(ConsultationStatus.ConsultationConfirmed);
  };

  const handleReschedule = () => {
    handleStatus(ConsultationStatus.ConsultationNeedsReschedule);
  };

  const handleCancel = () => {
    handleStatus(ConsultationStatus.ConsultationCanceled);
  };
  if (loading) {
    return (
      <Container maxWidth="sm" className={classes.container} style={{ justifyContent: 'center', alignItems: 'center' }}>
        <ClipLoader />
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Box mt={14}>
        {!consultationStateConfirmed && (
          <Typography variant="h4" align="center">
            Are you coming for your consultation?
          </Typography>
        )}
        {status === ConsultationStatus.ConsultationConfirmed && (
          <Fragment>
            <Typography gutterBottom variant="h4" align="center">
              Thank you!
            </Typography>
            <Typography color="textSecondary" align="center">
              Your visit is confirmed.
            </Typography>
          </Fragment>
        )}
        {status === ConsultationStatus.ConsultationNeedsReschedule && (
          <Fragment>
            <Typography gutterBottom variant="h4" align="center">
              No problem
            </Typography>
            <Typography color="textSecondary" align="center">
              Thank you for letting us know. Our staff will call you soon and reschedule your consultation for some
              other time.
            </Typography>
          </Fragment>
        )}
        {status === ConsultationStatus.ConsultationCanceled && (
          <Fragment>
            <Typography gutterBottom variant="h4" align="center">
              No problem
            </Typography>
            <Typography color="textSecondary" align="center">
              Thank you for letting us know.
            </Typography>
          </Fragment>
        )}
      </Box>

      <Box mt="25vh" mb="auto">
        <Zoom in={!consultationStateConfirmed} unmountOnExit>
          <Box>
            <StatusCard
              hospitalName={hospitalName}
              consultationDate={consultationDate}
              consultationTime={consultationTime}
            />
          </Box>
        </Zoom>

        <Zoom
          in={status === ConsultationStatus.ConsultationConfirmed}
          unmountOnExit
          style={{ transitionDelay: status === ConsultationStatus.ConsultationConfirmed ? '500ms' : '0ms' }}
        >
          <Box>
            <StatusCard
              confirmed
              hospitalName={hospitalName}
              consultationDate={consultationDate}
              consultationTime={consultationTime}
            />
          </Box>
        </Zoom>

        <Zoom
          in={status === ConsultationStatus.ConsultationNeedsReschedule}
          unmountOnExit
          style={{ transitionDelay: status === ConsultationStatus.ConsultationNeedsReschedule ? '1000ms' : '0ms' }}
        >
          <Box>
            <StatusCard
              reschedule
              hospitalName={hospitalName}
              consultationDate={consultationDate}
              consultationTime={consultationTime}
            />
          </Box>
        </Zoom>

        <Zoom
          in={status === ConsultationStatus.ConsultationCanceled}
          unmountOnExit
          style={{ transitionDelay: status === ConsultationStatus.ConsultationCanceled ? '1000ms' : '0ms' }}
        >
          <Box>
            <StatusCard
              canceled
              hospitalName={hospitalName}
              consultationDate={consultationDate}
              consultationTime={consultationTime}
            />
          </Box>
        </Zoom>
      </Box>

      {!consultationStateConfirmed && (
        <Box className={classes.actions} py={2}>
          <Button color="primary" size="large" onClick={handleConfirm} fullWidth>
            I will be there
          </Button>
          <Button fullWidth size="large" onClick={handleReschedule}>
            I need to reschedule
          </Button>
          <Button fullWidth size="large" onClick={handleCancel}>
            Cancel consultation
          </Button>
        </Box>
      )}

      {consultationStateConfirmed && (
        <Box py={8}>
          <Typography align="center" variant="subtitle1" color="textSecondary">
            No further action needed. Feel free to close this tab.
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default Confirmation;
