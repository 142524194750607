import { Messages } from '../types/localization';

const kr: Messages = {
  'questionnaire.call': '부르다',
  'questionnaire.directions': '구애',
  'questionnaire.welcome.title': '환자 온라인 등록에 오신 것을 환영합니다',
  'questionnaire.welcome.lead.hospital':
    '{hospitalName} 는 최상의 의료 서비스를 제공하기 위해 귀하의 건강 정보 및 의료 기록을 필요로 합니다. 이 온라인 설문지를 작성해 주십시오. 간단하고 안전하며 비밀이 보장됩니다.',
  'questionnaire.welcome.lead.generic':
    '우리는 가능한 최상의 치료를 제공하기 위해 귀하의 건강 정보와 의료 기록이 필요합니다. 온라인 설문지를 작성해 주시면 간편하고 안전하며 비밀스러운 서비스를 제공해드릴 수 있습니다.',
  'questionnaire.welcome.instructions.request': '다음 정보를 제공할 수 있는지 확인해 주십시오:',
  'questionnaire.welcome.instructions.prescription': '복용 중인 각 처방 약물의 이름과 용량.',
  'questionnaire.welcome.instructions.over-the-counter': '정기적으로 복용하는 비처방 약물의 이름과 용량.',
  'questionnaire.welcome.instructions.allergies': '가지고 있는 알레르기 및 해당 알레르기로 인한 반응.',
  'questionnaire.welcome.instructions.surgeries': '이전 수술 및 해당 수술이 수행된 연도',
  'questionnaire.welcome.call':
    '이 정보를 수령한 후, 직원 또는 간호사가 더 자세한 설명을 듣기 위해 전화를 드릴 수도 있습니다.',
  'questionnaire.welcome.end': '고객님께 최고의 의료 서비스 및 관리를 제공하겠습니다.',
  'questionnaire.welcome.continue': '계속하다',
  'questionnaire.tabs.questionnaire': '설문지',
  'questionnaire.tabs.docs': '이동',
  'questionnaire.tabs.signature': '사업',
  'questionnaire.error.submission':
    '양식을 전송하는 중에 오류가 발생했습니다. 다시 시도해주세요. 문제가 계속되면 지원 서비스 직원에게 문의해주세요.',
  'questionnaire.sections.annex.confirmation.title': '동작을 확인해주세요.',
  'questionnaire.sections.annex.confirmation.text':
    '이미 사전 의료 양식에 서명하셨습니다. 설문지를 변경하면 양식을 폐기해야 합니다. 수정하시겠습니까?',
  'questionnaire.sections.back': '뒤로',
  'questionnaire.sections.save.finish': '저장하고 끝내기',
  'questionnaire.sections.save.continue': '저장하고 계속',
  'questionnaire.list.add': '추가하다',
  'questionnaire.list.remove': '제거하다',
  'questionnaire.text.limit.used': `{remaining, plural,
        =1 {한 글자 남음}
        other {#자 남음}}`,
  'questionnaire.text.limit.unused': `최대 길이: {limit, plural,
        =1 {캐릭터}
        other {# 문자}}`,
  'questionnaire.dob.title': '생일',
  'questionnaire.dob.placeholder.day': 'DD',
  'questionnaire.dob.placeholder.month': 'MM',
  'questionnaire.dob.placeholder.year': 'AAAA',
  'questionnaire.dob.day': '일',
  'questionnaire.dob.month': '월',
  'questionnaire.dob.year': '년도',
  'questionnaire.date.placeholder.day': 'DD',
  'questionnaire.date.placeholder.month': 'MM',
  'questionnaire.date.placeholder.year': 'AAAA',
  'questionnaire.date.day': '일',
  'questionnaire.date.month': '월',
  'questionnaire.date.year': '년도',
  'questionnaire.bmi.height': '키',
  'questionnaire.bmi.feet': '피트',
  'questionnaire.bmi.inches': '인치',
  'questionnaire.bmi.weight': '무게',
  'questionnaire.bmi.pounds': '파운드',
  'questionnaire.csz.city': '도시',
  'questionnaire.csz.state': '주',
  'questionnaire.csz.zip': '우편 번호',
  'questionnaire.yesno.yes': '예',
  'questionnaire.yesno.no': '아니요',
  'questionnaire.oneof.choose': '옵션을 선택하세요',
  'questionnaire.medication.label': '이름',
  'questionnaire.medication.placeholder': '제안을 보려면 입력을 시작하세요',
  'questionnaire.checkboxes.question': '해당되는 모든 것을 체크하세요',
  'questionnaire.checkboxes.none': '위의 어느 것도 아닙니다',
  'questionnaire.success.call': '부르다',
  'questionnaire.success.directions': '구애',
  'questionnaire.success.thanks': '감사해요!',
  'questionnaire.success.docs.uploaded': '업로드된 문서.',
  'questionnaire.success.signature.submitted': '서명 전송됨.',
  'questionnaire.success.questionnaire.submitted': '설문지가 성공적으로 전송되었습니다 {progress}%',
  'questionnaire.success.staff.call':
    '설문지가 성공적으로 전송되었습니다. 직원 또는 간호사가 더 많은 설명을 위해 전화드릴 수 있습니다.',
  'questionnaire.success.back': '양식으로 돌아가기',
  'questionnaire.success.close': '닫기',
  'questionnaire.docs.title': '문서 업로드',
  'questionnaire.docs.empty': '여기에 문서가 없습니다.',
  'questionnaire.docs.delete': '삭제',
  'questionnaire.docs.confirm': '동작을 확인해주세요',
  'questionnaire.docs.sure': '정말로 이 파일을 삭제하시겠습니까?',
  'questionnaire.docs.finish': '완료',
  'questionnaire.signature.title': '전자 문서 서명',
  'questionnaire.signature.request': '읽고 확인하십시오',
  'questionnaire.signature.instruction': '여기에 서명해주세요',
  'questionnaire.signature.preview': '미리보기',
  'questionnaire.signature.agreement': '모두 동의합니다',
  'questionnaire.signature.clear': '데이터 삭제',
  'questionnaire.signature.back': '뒤로',
  'questionnaire.signature.finish': '완료',
  'questionnaire.signature.save': '저장 및 완료',
  'homepage.instructions': 'PIN 번호를 입력해주세요',
  'homepage.error.pin': 'PIN이 유효하지 않습니다. 다시 시도해주세요.',
  'homepage.error.unexpected': '예기치 않은 오류가 발생했습니다. 나중에 다시 시도해주세요.',
  'homepage.helper': '수술 센터로부터 받은 문자 메시지에 PIN을 찾을 수 있습니다.',
  'status.patient': '환자',
  'status.physician': '의사',
  'status.status': '상태',
  'status.time': '시간',
  'status.admitted': '입원',
  'status.preparing-for-surgery': '수술 준비',
  'status.in-surgery': '수술 중',
  'status.in-recovery': '수술 후 회복 중',
  'status.in-postop': '수술 후',
  'status.able-to-visit': '방문을 받을 수 있습니다',
  'status.final.title': '이 환자의 방문이 종료되었습니다.',
  'status.final.subtitle': '이 환자의 방문이 종료되었습니다.',

  'hospital.call': '전화',
  'hospital.directions': '길 찾기',
  'feedback.error':
    '양식을 제출하는 중에 오류가 발생했습니다. 다시 시도해주세요. 문제가 지속되는 경우 지원 서비스 직원에게 문의하십시오.',
  'feedback.share.question': '다른 사람들과 경험을 공유하시겠습니까?',
  'feedback.share.google': 'Google에 의견 남기기',
  'feedback.share.yelp': 'Yelp에서 평가하기',
  'feedback.share.skip': '건너뛰기',
  'feedback.suggestion.question': '의견을 남기거나 경험을 개선하는 방법을 알려주시겠습니까?',
  'feedback.send': '의견 보내기',
  'feedback.success.title': '감사합니다!',
  'feedback.success.text': '의견이 전송되었습니다.',
  'rating.instructions': '경험 평가하기',
  'rating.thanks': '감사합니다!',
  'This field is required': '이 필드는 필수입니다',
  'Please fill in the required fields': '필수 항목을 입력해주세요',
};

export default kr;
