import { useEffect, useState } from 'react';
import config from '../config';

export function useValueFromParent(name: string) {
  const [value, setValue] = useState(undefined);

  useEffect(() => {
    const requestId = Math.random();

    const handleMessage = (message: MessageEvent) => {
      if (message.source !== window.parent) {
        return;
      }

      if (message.data?.requestId !== requestId) {
        return;
      }

      setValue(message.data?.response);
    };

    window.addEventListener('message', handleMessage);

    window.parent.postMessage({ requestId, request: name }, config.appURL);

    return () => window.removeEventListener('message', handleMessage);
  }, [name]);

  return value;
}
