import gql from 'graphql-tag';

export const myConsultation = gql`
  query myConsultation {
    myConsultation {
      id
      consultationDateTime
      consultationDate
      consultationTime
      status
    }
  }
`;

export const setConsultationStatus = gql`
  mutation setConsultationStatus($status: ConsultationConfirmationType!) {
    setConsultationStatus(status: $status)
  }
`;
