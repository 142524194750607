import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';

const DischargedPatient = () => {
  const intl = useIntl();

  return (
    <div>
      <Typography variant="h1" align="center" gutterBottom>
        {intl.formatMessage({ id: 'status.final.title', defaultMessage: 'This patient’s visit has concluded.' })}
      </Typography>
      <Typography align="center" color="textSecondary">
        {intl.formatMessage({
          id: 'status.final.subtitle',
          defaultMessage: 'We appreciate the opportunity to provide care.',
        })}
      </Typography>
    </div>
  );
};

export default DischargedPatient;
