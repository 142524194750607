import React, { useMemo } from 'react';
import isArray from 'lodash/isArray';
import FormContext from './FormContext';
import renderFormNode from './renderFormNode';
import components from './components';
import { NestedFormWrapper } from './inputs/YesNoInput';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { CheckBoxOutlineBlankSharp, CheckBoxSharp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';

const useStyles = makeStyles(theme => ({
  checkboxNestable: {
    '& > div:last-of-type': {
      marginBottom: theme.spacing(4),

      '&:empty': {
        marginBottom: 0,
      },
    },
  },
}));

const makeName = (name, key) => (name ? `${name}.${key}` : key);

const SingleCheckboxInput = ({ label, ifChecked, omitNoneIfChecked, required, error }) => {
  const classes = useStyles();
  const theme = useTheme();
  const ifCheckedNode = useMemo(
    () => ifChecked && React.cloneElement(renderFormNode(ifChecked), { omitnone: omitNoneIfChecked }),
    [ifChecked, omitNoneIfChecked]
  );

  const isLongerText = label?.length > 40;

  return (
    <FormContext.Consumer>
      {({ name, value, onChange }) => {
        const handleChange = e => {
          if (ifChecked) {
            onChange(e.target.checked ? [true, (components()[ifChecked['type']] || {}).defaultValue] : null);
          } else {
            onChange(e.target.checked || null);
          }
        };

        const checked = (isArray(value) ? value[0] : value) === true;

        return (
          <div
            className={classes.checkboxNestable}
            style={isLongerText ? { marginBottom: theme.spacing(1) } : undefined}
          >
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankSharp fontSize="large" />}
                  checkedIcon={<CheckBoxSharp fontSize="large" />}
                  type="checkbox"
                  name={name}
                  value="No"
                  checked={checked}
                  required={required}
                  error={error}
                  onChange={handleChange}
                  style={isLongerText ? { alignSelf: 'flex-start' } : undefined}
                />
              }
              label={<div style={isLongerText ? { paddingTop: '9px' } : undefined}>{label}</div>}
            />
            {checked && (
              <NestedFormWrapper>
                <FormContext.Provider
                  value={{
                    name: makeName(name, 'yes'),
                    value: value[1],
                    onChange: v => onChange([true, v]),
                  }}
                >
                  {ifCheckedNode}
                </FormContext.Provider>
              </NestedFormWrapper>
            )}
          </div>
        );
      }}
    </FormContext.Consumer>
  );
};

SingleCheckboxInput.defaultValue = false;

export default SingleCheckboxInput;
