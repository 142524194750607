import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import React from 'react';
import { Button } from '@material-ui/core';

export const ConfirmationDialog = props => {
  const handleClose = confirm => {
    props.setOpen(false);
    if (confirm) {
      props.onConfirm();
    } else if (props.onDecline) {
      props.onDecline();
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{props.text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary" fullWidth>
          No
        </Button>
        <Button onClick={() => handleClose(true)} fullWidth>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
