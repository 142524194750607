import React, { useState } from 'react';
import get from 'lodash/get';
import FormContext from '../FormContext';
import InputWrapper from '../InputWrapper';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { Disclaimer, Document } from '../../../sections/Questionnaire/documentSignature/DocumentSignatureStyle';
import Typography from '@material-ui/core/Typography';
import { readTitle } from '../../../sections/Questionnaire/documentSignature/DocumentSignature';
import { isString } from 'lodash';
import { PdfPreview } from '../../../sections/Questionnaire/documentSignature/FormPreview';
import { CheckBoxOutlineBlankSharp, CheckBoxSharp } from '@material-ui/icons';

const DocumentsApprovalInput = ({ lead, documents }) => {
  const intl = useIntl();

  const [previewId, setPreviewId] = useState();

  const handlePreviewClick = id => () => setPreviewId(id);

  const handlePreviewClose = () => setPreviewId(undefined);

  const docKeys = documents ? Object.keys(documents) : [];
  return (
    <FormContext.Consumer>
      {({ value, onChange }) => {
        const allChecked = docKeys.reduce((acc, doc) => [...acc, !!value?.value?.[doc]], []).every(e => e);

        const handleIsChecked = id => e => {
          const nextValue = {
            ...get(value, 'value', {}),
            // something didn't work well with e.target.checked
            // [id]: e.target.checked ? new Date().toISOString() : null,
            [id]: !!value?.value?.[id] ? null : new Date().toISOString(),
          };

          const progress = docKeys.filter(id => Boolean(nextValue[id])).length;

          onChange({ value: nextValue, progress });
        };

        const handleIsCheckedAll = e => {
          const nextValue = docKeys.reduce(
            (docs, doc) => ({
              ...docs,
              [doc]: e.target.checked ? new Date().toISOString() : null,
            }),
            {}
          );

          const progress = docKeys.filter(id => Boolean(nextValue[id])).length;

          onChange({ value: nextValue, progress });
        };

        return previewId ? (
          <PdfPreview url={documents[previewId].url} onBack={handlePreviewClose} />
        ) : (
          <InputWrapper>
            {lead && (
              <Box mb={4}>
                <Typography color="textSecondary">
                  <FormattedMessage {...(isString(lead) ? { id: lead, defaultMessage: lead } : lead)} />
                </Typography>
              </Box>
            )}

            {Object.entries(documents).map(([id, document]) => (
              <Document key={id}>
                <Box display="flex" ml={-1}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankSharp fontSize="large" />}
                    checkedIcon={<CheckBoxSharp fontSize="large" />}
                    type="checkbox"
                    checked={get(value, ['value', id], false)}
                    onChange={handleIsChecked(id)}
                  />
                  <Disclaimer>
                    <Typography>
                      {readTitle(
                        intl.formatMessage(
                          isString(document.name) ? { id: document.name, defaultMessage: document.name } : document.name
                        )
                      )}
                    </Typography>
                  </Disclaimer>
                  <Button variant="text" color="primary" onClick={handlePreviewClick(id)}>
                    {intl.formatMessage({ id: 'questionnaire.signature.preview', defaultMessage: 'Preview' })}
                  </Button>
                </Box>
              </Document>
            ))}

            <FormControlLabel
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankSharp fontSize="large" />}
                  checkedIcon={<CheckBoxSharp fontSize="large" />}
                  type="checkbox"
                  checked={allChecked || false}
                  onChange={handleIsCheckedAll}
                />
              }
              label={intl.formatMessage({ id: 'questionnaire.signature.agreement', defaultMessage: 'I agree to all' })}
            />
          </InputWrapper>
        );
      }}
    </FormContext.Consumer>
  );
};

DocumentsApprovalInput.defaultValue = { progress: 0 };

export default DocumentsApprovalInput;
