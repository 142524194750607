import LockIcon from "@material-ui/icons/Lock"
import { Avatar, makeStyles } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
  }
}));

const LockedAdornment = () => {
  const classes = useStyles();

  return (
    <Avatar className={classes.avatar}>
      <LockIcon/>
    </Avatar>
  )
}

export default LockedAdornment;
