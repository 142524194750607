import { Messages } from '../types/localization';

const tl: Messages = {
  'questionnaire.call': 'Tawagan',
  'questionnaire.directions': 'Direksyon',
  'questionnaire.welcome.title': 'Maligayang pagdating sa online na rehistrasyon ng pasyente',
  'questionnaire.welcome.lead.hospital':
    'Kailangan ng {hospitalName} ng impormasyon tungkol sa iyong kalusugan at medikal na kasaysayan upang maibigay ang pinakamahusay na pangangalaga sa iyo. Mangyaring punan ang online na survey na ito. Ito ay simple, ligtas at kumpidensyal.',
  'questionnaire.welcome.lead.generic':
    'Kailangan namin ang impormasyon tungkol sa iyong kalusugan at medikal na kasaysayan upang maibigay ang pinakamahusay na pangangalaga sa iyo. Mangyaring punan ang online na survey na ito. Ito ay simple, ligtas at kumpidensyal.',
  'questionnaire.welcome.instructions.request':
    'Mangyaring tiyakin na mayroon kang sumusunod na impormasyon sa iyong kamay:',
  'questionnaire.welcome.instructions.prescription': 'Pangalan at dosis ng iyong mga reseta ng gamot',
  'questionnaire.welcome.instructions.over-the-counter':
    'Pangalan at dosis ng mga over-the-counter na gamot na inyong iniinom',
  'questionnaire.welcome.instructions.allergies': 'Mga allergy na maaaring inyong mayroon at ang mga reaksyon dito',
  'questionnaire.welcome.instructions.surgeries': 'Mga nakaraang operasyon at ang taon kung kailan ito naganap',
  'questionnaire.welcome.call':
    'Pagkatapos ng pagtanggap ng impormasyong ito, maaaring tumanggap kayo ng tawag mula sa isang miyembro ng staff o isang nars upang linawin ang ilang mga punto.',
  'questionnaire.welcome.end':
    'Inaasahan naming magbibigay kami ng pinakamahusay na pangangalaga at serbisyong medikal',
  'questionnaire.welcome.continue': 'Magpatuloy',
  'questionnaire.tabs.questionnaire': 'Survey',
  'questionnaire.tabs.docs': 'Mag-upload',
  'questionnaire.tabs.signature': 'Lagda',
  'questionnaire.error.submission':
    'May naganap na error sa pagpasa ng form. Mangyaring subukan muli. Kung patuloy ang problema, mangyaring makipag-ugnayan sa tauhan ng customer support.',
  'questionnaire.sections.annex.confirmation.title': 'Kumpirmahin ang Aksyon',
  'questionnaire.sections.annex.confirmation.text':
    'Ikaw ay naglagda na ng mga pre-medical form. Kapag binago mo ang survey, kailangan mong ibasura ang mga form. Gusto mo bang baguhin ito?',
  'questionnaire.sections.back': 'Bumalik',
  'questionnaire.sections.save.finish': 'I-save at tapusin',
  'questionnaire.sections.save.continue': 'I-save at Ipatuloy',
  'questionnaire.list.add': 'Magdagdag',
  'questionnaire.list.remove': 'Tanggalin',
  'questionnaire.text.limit.used':
    '{remaining, plural, =1 {May natitirang character} other {May natitirang # na characters}}',
  'questionnaire.text.limit.unused': 'Maximum na haba: {limit, plural, =1 {Isang character} other {# na characters}}',
  'questionnaire.dob.title': 'Petsa ng kapanganakan',
  'questionnaire.dob.placeholder.day': 'DD',
  'questionnaire.dob.placeholder.month': 'MM',
  'questionnaire.dob.placeholder.year': 'YYYY',
  'questionnaire.dob.day': 'Araw',
  'questionnaire.dob.month': 'Buwan',
  'questionnaire.dob.year': 'Taon',
  'questionnaire.date.placeholder.day': 'DD',
  'questionnaire.date.placeholder.month': 'MM',
  'questionnaire.date.placeholder.year': 'YYYY',
  'questionnaire.date.day': 'Araw',
  'questionnaire.date.month': 'Buwan',
  'questionnaire.date.year': 'Taon',
  'questionnaire.bmi.height': 'Taas',
  'questionnaire.bmi.feet': 'Paa',
  'questionnaire.bmi.inches': 'Pulgada',
  'questionnaire.bmi.weight': 'Bigat',
  'questionnaire.bmi.pounds': 'Libra',
  'questionnaire.csz.city': 'Lungsod',
  'questionnaire.csz.state': 'Estado',
  'questionnaire.csz.zip': 'Zip Code',
  'questionnaire.yesno.yes': 'Oo',
  'questionnaire.yesno.no': 'Hindi',
  'questionnaire.oneof.choose': 'Pumili ng isang opsyon',
  'questionnaire.medication.label': 'Pangalan',
  'questionnaire.medication.placeholder': 'Magsimulang mag-type para sa mga mungkahi',
  'questionnaire.checkboxes.question': 'Tandang lahat ng naaangkop',
  'questionnaire.checkboxes.none': 'Wala sa mga nabanggit',
  'questionnaire.success.call': 'Tawagan',
  'questionnaire.success.directions': 'Direksyon',
  'questionnaire.success.thanks': 'Salamat!',
  'questionnaire.success.docs.uploaded': 'Mga dokumento na-upload.',
  'questionnaire.success.signature.submitted': 'Nai-submit ang lagda.',
  'questionnaire.success.questionnaire.submitted': 'Ang questionnaire ay matagumpay na naipasa {progress}%',
  'questionnaire.success.staff.call':
    'Matagumpay na ipinasa ang questionnaire. Maaaring tawagan ka ng isang staff member o nars para sa karagdagang paliwanag.',
  'questionnaire.success.back': 'Bumalik sa form',
  'questionnaire.success.close': 'malapit na',
  'questionnaire.docs.title': 'Pag-upload ng mga Dokumento',
  'questionnaire.docs.empty': 'Walang dokumento dito.',
  'questionnaire.docs.delete': 'Tanggalin',
  'questionnaire.docs.confirm': 'Kumpirmahin ang aksyon',
  'questionnaire.docs.sure': 'Sigurado ka bang gusto mong tanggalin ang file na ito?',
  'questionnaire.docs.finish': 'Tapusin',
  'questionnaire.signature.title': 'Electronic Signature ng Dokumento',
  'questionnaire.signature.request': 'Mangyaring basahin ang mga elektronikong dokumento',
  'questionnaire.signature.instruction': 'Lagdaan dito',
  'questionnaire.signature.preview': 'Preview',
  'questionnaire.signature.agreement': 'Sumasang-ayon ako sa paggamit ng mga rekord at elektronikong lagda',
  'questionnaire.signature.clear': 'Burahin ang data',
  'questionnaire.signature.back': 'Bumalik',
  'questionnaire.signature.finish': 'Tapusin',
  'questionnaire.signature.save': 'I-save at tapusin',
  'homepage.instructions': 'Mangyaring ilagay ang iyong PIN',
  'homepage.error.pin': 'Hindi wastong PIN, subukan muli.',
  'homepage.error.unexpected': 'Naganap ang isang di-inaasahang error, subukan muli mamaya.',
  'homepage.helper': 'Maaari mong mahanap ang PIN sa mensaheng natanggap mo mula sa sentro ng pagtitistis.',
  'status.patient': 'Pasyente',
  'status.physician': 'Doktor',
  'status.status': 'Katayuan',
  'status.time': 'Oras',
  'status.admitted': 'Naiadmit',
  'status.preparing-for-surgery': 'Nagpapaghanda para sa operasyon',
  'status.in-surgery': 'Nasa Operasyon',
  'status.in-recovery': 'Nasa Pagpapalakas',
  'status.in-postop': 'Nasa Postoperatibong Panahon',
  'status.able-to-visit': 'Maaaring Bisitahin',
  'status.final.title': 'Natapos na ang pagbisita ng pasyenteng ito.',
  'status.final.subtitle': 'Natapos na ang pagbisita ng pasyenteng ito.',

  'hospital.call': 'Tawagan',
  'hospital.directions': 'Humiling ng Direksyon',
  'feedback.error':
    'May naganap na error sa pagpapadala ng form. Mangyaring subukan muli. Kung patuloy ang problema, makipag-ugnayan sa mga tauhan ng customer service.',
  'feedback.share.question': 'Gusto mo bang ibahagi ang iyong karanasan sa iba?',
  'feedback.share.google': 'Mag-iwan ng komento sa Google',
  'feedback.share.yelp': 'Bigyan kami ng rating sa Yelp!',
  'feedback.share.skip': 'Laktawan',
  'feedback.suggestion.question':
    'Gusto mo bang mag-iwan ng komento o sabihin sa amin kung paano natin maaaring mapabuti ang iyong karanasan?',
  'feedback.send': 'Ipadala ang Feedback',
  'feedback.success.title': 'Maraming Salamat!',
  'feedback.success.text': 'Naipadala na ang iyong mungkahi.',
  'rating.instructions': 'Magbigay ng marka sa iyong karanasan',
  'rating.thanks': 'Maraming salamat!',
  'This field is required': 'Kinakailangan ang patlang na ito',
  'Please fill in the required fields': 'Mangyaring punan ang mga kinakailangang patlang',
};

export default tl;
