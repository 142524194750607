import isString from 'lodash/isString';
import isBoolean from 'lodash/isBoolean';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';

/**
 * type Date = {year?: string, month?: string, day?: string}
 */
const validateDate = (date: any): boolean => !!(date.year && date.month && date.day);

/**
 * type BMI = {heightFt?: string, heightIn?: string, weightLb?: string}
 */
const validateBMI = (bmi: any): boolean => !!(bmi.heightFt && bmi.heightIn && bmi.weightLb);

/**
 * type CityStateZip = {city?: string, state?: string, zipCode?: string}
 */
const validateCityStateZip = (val: any): boolean => !!(val.city && val.state && val.zipCode);

/**
 * type Checkboxes = {
 *   [key: string] :  boolean | null | [boolean, object]
 * } | []
 *
 * [] - selected checkbox "None of the above"
 */
const validateCheckboxes = (val: any): boolean =>
  val && !isArray(val) && isObject(val) ? Object.values(val).some(e => !!e) : !!(val && isArray(val));

const validateText = (value?: any): boolean => (isString(value) ? !!value : false);
const validateBoolean = (value?: any): boolean => !!isBoolean(value);

export enum FieldType {
  Text = 'Text',
  Date = 'Date',
  BodyMassIndex = 'BodyMassIndex',
  CityStateZip = 'CityStateZip',
  OneOf = 'OneOf',
  YesNo = 'YesNo',
  Checkboxes = 'Checkboxes',
}

export enum ValidatorType {
  isRequired = 'isRequired',
}

const validateRequired =
  (type: FieldType, msg: string = 'This field is required') =>
  (value: any): string | null => {
    switch (type) {
      case FieldType.Text:
      case FieldType.OneOf:
        return validateText(value) ? null : msg;
      case FieldType.YesNo:
        return validateBoolean(value) ? null : msg;
      case FieldType.Date:
        return validateDate(value) ? null : msg;
      case FieldType.Checkboxes:
        return validateCheckboxes(value) ? null : msg;
      case FieldType.BodyMassIndex:
        return validateBMI(value) ? null : msg;
      case FieldType.CityStateZip:
        return validateCityStateZip(value) ? null : msg;
      default:
        return msg;
    }
  };

export const validatorFn = {
  [ValidatorType.isRequired]: validateRequired,
};

export default validateRequired;
