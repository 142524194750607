import gql from 'graphql-tag';

export const fragments = {
  all: gql`
    fragment MedicationData on Medication {
      id
      drugName
      activeIngredient
      form
      strength
      sponsorName
    }
  `,
};

export const searchMedications = gql`
  query searchMedications($search: String!) {
    searchMedications(search: $search) {
      ...MedicationData
    }
  }
  ${fragments.all}
`;
