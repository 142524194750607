enum PatientStatus {
  ADMITTED = 'ADMITTED',
  PRE_OP = 'PRE_OP',
  IN_OR = 'IN_OR',
  PACU = 'PACU',
  ABLE_TO_VISIT_PACU = 'ABLE_TO_VISIT_PACU',
  ABLE_TO_VISIT_POSTOP = 'ABLE_TO_VISIT_POSTOP',
  IN_POST_OP = 'IN_POST_OP',
  DISCHARGED = 'DISCHARGED',
}

export default PatientStatus;
