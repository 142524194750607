import styled from 'styled-components';
import React from 'react';

const HtmlInput = styled.input`
  background: transparent;
  border-width: 0.125rem;
  border-style: solid;
  border-color: #e6ecef;
  border-radius: 0.125rem;
  color: #071334;
  padding: calc(1rem * 0.75 - 0.125rem);
  transition: 150ms all ease-in-out;
  font-size: calc(1.5 * 1rem);
  line-height: calc(1.3 * 0.75rem);

  &[type='number'] {
    width: 100%;
  }
`;

const TextInput = ({
  Input = HtmlInput,
  placeholder = '',
  isHidden,
  autoFocus,
  value,
  onChange,
  isDisabled,
  name = 'text',
  error,
  hasError,
  htmlType,
  maxLength,
  ...rest
}) => (
  <Input
    id={name}
    type={isHidden ? 'hidden' : htmlType ? htmlType : 'text'}
    name={name}
    autoComplete="on"
    autoCapitalize="off"
    autoCorrect="off"
    placeholder={placeholder}
    autoFocus={autoFocus || false}
    value={value || ''}
    checked={!!value}
    onChange={e => {
      const nextValue = htmlType === 'checkbox' ? e.target.checked : e.target.value;
      onChange(isFinite(maxLength) ? nextValue.substring(0, maxLength) : nextValue);
    }}
    disabled={isDisabled}
    hasError={hasError}
    {...rest}
  />
);

const CheckboxLayout = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  position: relative;

  > input {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
  }

  input + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  input + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
    flex-basis: 1.25rem;
    background: transparent;
    border: 0.125rem solid #00a7f7;
    border-radius: 0.125rem;
    align-self: baseline;
  }

  // Box checked
  input:checked + label:before {
    background: #00a7f7;
  }

  // Checkmark
  input:checked + label:after {
    font-family: 'Material Icons';
    content: '\\e5ca';
    font-size: 1.25rem;
    line-height: 1;
    color: white;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const CheckboxLabel = styled.label`
  position: relative;
  display: flex;
  line-height: 1.375;
  align-items: flex-end;
`;

const CheckboxInput = props => (
  <CheckboxLayout {...(props.layoutProps || {})}>
    <TextInput {...props} htmlType="checkbox" />
    <CheckboxLabel htmlFor={props.name}>{props.label}</CheckboxLabel>
  </CheckboxLayout>
);

export default CheckboxInput;
