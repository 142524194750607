import React from 'react';
import { getCurrentStatus, getStatusObject, getStatusTimestamps } from './helpers';
import PatientProgressTracker from './status/PatientProgressTracker';

const PatientStatusDetails = ({ patient = {}, hospital = {} }) => {
  const currentStatus = getCurrentStatus(patient.events || {});
  const statusObject = getStatusObject(patient.events || {});
  const statusTimestamps = getStatusTimestamps(patient.events || {});
  const omitAbleToVisit = hospital.omitAbleToVisit || false;
  const showPostOp = hospital.showPostOp || false;

  return (
    <PatientProgressTracker
      {...statusObject}
      {...statusTimestamps}
      current={currentStatus}
      omitAbleToVisit={omitAbleToVisit}
      showPostOp={showPostOp}
      hospital={hospital}
    />
  );
};

export default PatientStatusDetails;
