import React, { Dispatch, ReactNode, SetStateAction, useEffect, useRef } from 'react';
import { useSectionContextValidation } from './SectionContext';

type Rule<A> = (value: A) => string | undefined;

interface ValidationWrapperProps<A> {
  value: A;
  error?: string | null;
  setError: Dispatch<SetStateAction<string | undefined | null>>;
  rules: Rule<A>[];
  children: ReactNode;
}

export default function ValidationWrapper<A>({ value, rules, error, setError, children }: ValidationWrapperProps<A>) {
  const valueRef = useRef<A>(value);
  // const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    valueRef.current = value;
    setError(undefined);
  }, [value, setError]);

  useSectionContextValidation(() => {
    const failedRule = rules.find(rule => rule(valueRef.current));
    if (failedRule) {
      setError(failedRule(valueRef.current));
    }

    return !failedRule;
  });

  return (
    <>
      {children}
      {/*{error ? error : null}*/}
    </>
  );
}
