import React from 'react';
import { Query } from 'react-apollo';
import { hospital } from '../../graphql/schema/familyMember';
import { get } from 'lodash';
import { DirectionsTwoTone, PhoneTwoTone } from '@material-ui/icons';
import { Button, makeStyles, Paper, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

export const getGMapsLink = address => `https://www.google.com/maps/place/${(address || '').split(' ').join('+')}`;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',

    '& > *': {
      margin: theme.spacing(1),
    },
  },
  logo: {
    width: '100%',
    maxWidth: '8em',
    marginBottom: theme.spacing(2),
  },
}));

const HospitalInformation = () => {
  const classes = useStyles();

  return (
    <Query query={hospital}>
      {({ data: { patientHospital: hospital } }) =>
        hospital ? (
          <Paper className={classes.root}>
            {get(hospital, 'branding.careAppLogo') && (
              <img alt="hospital logo" src={hospital.branding.careAppLogo} className={classes.logo} />
            )}
            <Typography variant="h4" gutterBottom>
              {hospital.name}
            </Typography>
            <div className={classes.buttons}>
              {hospital.contact && (
                <Button
                  variant="outlined"
                  size="large"
                  component="a"
                  href={`tel:${hospital.contact}`}
                  startIcon={<PhoneTwoTone />}
                  fullWidth
                >
                  <FormattedMessage id="hospital.call" defaultMessage="Call" />
                </Button>
              )}
              {hospital.address && (
                <Button
                  variant="outlined"
                  size="large"
                  component="a"
                  href={getGMapsLink(hospital.address)}
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={<DirectionsTwoTone />}
                  fullWidth
                >
                  <FormattedMessage id="hospital.directions" defaultMessage="Directions" />
                </Button>
              )}
            </div>
          </Paper>
        ) : null
      }
    </Query>
  );
};

export default HospitalInformation;
