import React, { Fragment, useRef, useState } from 'react';
import { Box, Button, Container, Icon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SignaturePad from 'react-signature-canvas';
import { useMutation, useQuery } from 'react-apollo-hooks';
import { hospital as hospitalQuery } from '../graphql/schema/familyMember';
import { ClipLoader } from 'react-spinners';
import get from 'lodash/get';
import { uploadPhysicianSignature } from '../graphql/schema/physician';
import { Paragraph } from '../sections/Questionnaire/documentSignature/DocumentSignatureStyle';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const RequestSignature = () => {
  const classes = useStyles();

  const canvasRef = useRef({});

  const { data: hospitalData, loading } = useQuery(hospitalQuery);
  const hospital = hospitalData?.patientHospital;
  const hospitalName = get(hospital, 'name', '');

  const uploadSignature = useMutation(uploadPhysicianSignature);
  const [confirmed, setConfirmed] = useState(false);

  const handleConfirm = () => {
    uploadSignature({
      variables: { signature: canvasRef.current.getTrimmedCanvas().toDataURL('image/png') },
    })
      .then(() => {
        setConfirmed(true);
      })
      .catch(e => {
        setConfirmed(false);
        console.log(e);
      });
  };

  const handleClear = () => {
    canvasRef.current.clear();
  };

  if (loading) {
    return (
      <Container maxWidth="sm" className={classes.container} style={{ justifyContent: 'center', alignItems: 'center' }}>
        <ClipLoader />
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" className={classes.container}>
      {confirmed ? (
        <Box my="auto" style={{ textAlign: 'center' }} color="palette.success.main">
          <Typography gutterBottom variant="h5">
            Thank you!
          </Typography>
          <Typography gutterBottom color="textSecondary">
            Your signature is successfully uploaded.
          </Typography>
          <Icon fontSize="large">check</Icon>
        </Box>
      ) : (
        <Fragment>
          <Box py={6}>
            <Typography variant="h5" align="center" gutterBottom>
              Please write your signature
            </Typography>
            <Typography align="center" color="textSecondary">
              I approve using my electronic signature on {hospitalName} patient forms.
            </Typography>
          </Box>

          <Box my="auto">
            <Box display="flex" flex={1} flexDirection="row" justifyContent="space-between" alignItems="center">
              <Paragraph lead>Draw your signature here</Paragraph>
              <Button
                variant="contained"
                size="small"
                onClick={handleClear}
                style={{
                  textTransform: 'none',
                  backgroundColor: '#D3D3D3',
                  color: '#000c3f',
                  fontSize: '1em',
                  border: 'none',
                }}
              >
                X Clear
              </Button>
            </Box>
            <SignaturePad
              canvasProps={{
                className: 'signatureCanvas',
              }}
              clearOnResize={false}
              ref={canvasRef}
            />
          </Box>

          <Box py={2} mt="auto" mb={8}>
            <Button color="primary" onClick={handleConfirm} variant="contained" size="large" fullWidth>
              Confirm Your Signature
            </Button>
          </Box>
        </Fragment>
      )}
    </Container>
  );
};

export default RequestSignature;
