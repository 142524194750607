import React from 'react';
import NotAvailable from '../assets/NotAvailable.svg';
import Typography from '@material-ui/core/Typography';
import { Container, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
  },
  image: {
    height: theme.spacing(25),
    marginBottom: theme.spacing(2),
  },
}));

const Unauthorized = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container} maxWidth="sm">
      <img alt="not available" className={classes.image} src={NotAvailable} />
      <Typography variant="h5" align="center">
        The requested page does not exist or has expired.
      </Typography>
    </Container>
  );
};

export default Unauthorized;
