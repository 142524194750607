import React from 'react';
import gql from "graphql-tag";
import {useQuery} from "react-apollo-hooks";
import {ProcedureContext, ProcedureContextType} from "./ProcedureContext";

const GET_PROCEDURE_CONTEXT = gql`
  query patientProcedureContext {
    patientProcedureContext {
      anesthesiaType
      patientAge
      patientSex
      physicianSpecialties
      physicianCategories
    }
  }
`;

export const PatientProcedureContextProvider = ({ children }: { children: React.ReactNode; }) => {
  const { data } = useQuery(GET_PROCEDURE_CONTEXT);

  const procedureContext: ProcedureContextType = {
    anesthesiaType: data?.patientProcedureContext?.anesthesiaType,
    patientAge: data?.patientProcedureContext?.patientAge,
    patientSex: data?.patientProcedureContext?.patientSex,
    physicianSpecialties: data?.patientProcedureContext?.physicianSpecialties,
    physicianCategories: data?.patientProcedureContext?.physicianCategories,
  };

  return (
    <ProcedureContext.Provider value={procedureContext}>
      {children}
    </ProcedureContext.Provider>
  );
};

