import { Messages } from '../types/localization';

const fa: Messages = {
  'questionnaire.call': 'تماس',
  'questionnaire.directions': 'دستورالعمل ها',
  'questionnaire.welcome.title': 'به ثبت نام آنلاین بیمار خوش آمدید',
  'questionnaire.welcome.lead.hospital':
    '{hospitalName} نیاز به اطلاعات سلامت و سابقه پزشکی شما دارد تا بتواند بهترین مراقبت را برای شما فراهم کند. لطفاً این پرسشنامه آنلاین را پر کنید. این روند ساده، امن و محرمانه است.',
  'questionnaire.welcome.lead.generic':
    'نیاز به اطلاعات سلامت و سابقه پزشکی شما داریم تا بتوانیم بهترین مراقبت را برای شما فراهم کنیم. لطفاً این پرسشنامه آنلاین را پر کنید. این روند ساده، امن و محرمانه است.',
  'questionnaire.welcome.instructions.request': 'لطفاً اطلاعات زیر را در دسترس داشته باشید:',
  'questionnaire.welcome.instructions.prescription': 'نام و دوز داروهای تجویزی شما',
  'questionnaire.welcome.instructions.over-the-counter': 'نام و دوز داروهای غیر تجویزی که مصرف می کنید',
  'questionnaire.welcome.instructions.allergies': 'آلرژی هایی که ممکن است داشته باشید و واکنش های آنها',
  'questionnaire.welcome.instructions.surgeries': 'جراحی های قبلی و سال هایی که انجام شده است',
  'questionnaire.welcome.call':
    'بعد از دریافت این اطلاعات، ممکن است تماسی از یکی از کارکنان یا پرستاران با شما برقرار شود تا برخی نکات را روشن کند.',
  'questionnaire.welcome.end': 'امیدواریم بهترین مراقبت و خدمات پزشکی را برای شما فراهم کنیم.',
  'questionnaire.welcome.continue': 'ادامه',
  'questionnaire.tabs.questionnaire': 'پرسشنامه',
  'questionnaire.tabs.docs': 'بارگذاری',
  'questionnaire.tabs.signature': 'امضا',
  'questionnaire.error.submission':
    'در هنگام ارسال فرم خطایی رخ داده است. لطفاً مجدداً امتحان کنید. اگر مشکل برقرار است، با کادر خدمات پشتیبانی تماس بگیرید.',
  'questionnaire.sections.annex.confirmation.title': 'تایید عمل',
  'questionnaire.sections.annex.confirmation.text':
    'شما قبلاً فرم های پیش درمانی را امضا کرده اید. با تغییر در پرسشنامه، باید فرم ها را دور بیندازید. آیا میخواهید ویرایش کنید؟',
  'questionnaire.sections.back': 'بازگشت',
  'questionnaire.sections.save.finish': 'ذخیره و پایان',
  'questionnaire.sections.save.continue': 'ذخیره و ادامه',
  'questionnaire.list.add': 'افزودن',
  'questionnaire.list.remove': 'حذف',
  'questionnaire.text.limit.used':
    '{remaining, plural, =1 {1 کاراکتر باقی مانده است} other {# کاراکتر باقی مانده است}}',
  'questionnaire.text.limit.unused': 'حداکثر طول: {limit, plural, =1 {1 کاراکتر} other {# کاراکتر}}',
  'questionnaire.dob.title': 'تاریخ تولد',
  'questionnaire.dob.placeholder.day': 'روز',
  'questionnaire.dob.placeholder.month': 'ماه',
  'questionnaire.dob.placeholder.year': 'سال',
  'questionnaire.dob.day': 'روز',
  'questionnaire.dob.month': 'ماه',
  'questionnaire.dob.year': 'سال',
  'questionnaire.date.placeholder.day': 'روز',
  'questionnaire.date.placeholder.month': 'ماه',
  'questionnaire.date.placeholder.year': 'سال',
  'questionnaire.date.day': 'روز',
  'questionnaire.date.month': 'ماه',
  'questionnaire.date.year': 'سال',
  'questionnaire.bmi.height': 'قد',
  'questionnaire.bmi.feet': 'فوت',
  'questionnaire.bmi.inches': 'اینچ',
  'questionnaire.bmi.weight': 'وزن',
  'questionnaire.bmi.pounds': 'پوند',
  'questionnaire.csz.city': 'شهر',
  'questionnaire.csz.state': 'استان',
  'questionnaire.csz.zip': 'کدپستی',
  'questionnaire.yesno.yes': 'بله',
  'questionnaire.yesno.no': 'خیر',
  'questionnaire.oneof.choose': 'یک گزینه را انتخاب کنید',
  'questionnaire.medication.label': 'نام',
  'questionnaire.medication.placeholder': 'برای مشاهده پیشنهادها تایپ کنید',
  'questionnaire.checkboxes.question': 'همه موارد مربوطه را علامت بزنید',
  'questionnaire.checkboxes.none': 'هیچکدام از گزینه‌های بالا',
  'questionnaire.success.call': 'تماس',
  'questionnaire.success.directions': 'دستورالعمل ها',
  'questionnaire.success.thanks': 'با تشکر!',
  'questionnaire.success.docs.uploaded': 'اسناد بارگذاری شده.',
  'questionnaire.success.signature.submitted': 'امضا ارسال شد.',
  'questionnaire.success.questionnaire.submitted': 'پرسشنامه با موفقیت ارسال شد {progress}%',
  'questionnaire.success.staff.call':
    'پرسشنامه با موفقیت ارسال شد. یکی از کارکنان یا پرستارها ممکن است با شما تماس بگیرد تا اطلاعات بیشتری دریافت کند.',
  'questionnaire.success.back': 'بازگشت به فرم',
  'questionnaire.success.close': 'بستن',
  'questionnaire.docs.title': 'بارگذاری اسناد',
  'questionnaire.docs.empty': 'هیچ سندی موجود نیست.',
  'questionnaire.docs.delete': 'حذف',
  'questionnaire.docs.confirm': 'تایید عملیات',
  'questionnaire.docs.sure': 'آیا از حذف این فایل اطمینان دارید؟',
  'questionnaire.docs.finish': 'پایان',
  'questionnaire.signature.title': 'امضای الکترونیکی سند',
  'questionnaire.signature.request': 'لطفاً سند الکترونیکی را بخوانید',
  'questionnaire.signature.instruction': 'امضای خود را اینجا قرار دهید',
  'questionnaire.signature.preview': 'پیش نمایش',
  'questionnaire.signature.agreement': 'موافقم با استفاده از سوابق و امضاهای الکترونیکی',
  'questionnaire.signature.clear': 'پاک کردن داده‌ها',
  'questionnaire.signature.back': 'بازگشت',
  'questionnaire.signature.finish': 'پایان',
  'questionnaire.signature.save': 'ذخیره و پایان',
  'homepage.instructions': 'لطفاً پین خود را وارد کنید',
  'homepage.error.pin': 'پین وارد شده معتبر نمی باشد، لطفاً مجدداً تلاش کنید.',
  'homepage.error.unexpected': 'خطای غیرمنتظره رخ داد، لطفاً بعداً مجدداً تلاش کنید.',
  'homepage.helper': 'شما می‌توانید پین را در پیامکی که از مرکز جراحی دریافت کرده‌اید پیدا کنید.',
  'status.patient': 'بیمار',
  'status.physician': 'پزشک',
  'status.status': 'وضعیت',
  'status.time': 'زمان',
  'status.admitted': 'پذیرفته شده',
  'status.preparing-for-surgery': 'در حال آماده‌سازی برای جراحی',
  'status.in-surgery': 'در جراحی',
  'status.in-recovery': 'در بازیابی',
  'status.in-postop': 'در پساعمل',
  'status.able-to-visit': 'قابلیت دریافت ویزیت',
  'status.final.title': 'بازدید از این بیمار به پایان رسیده است.',
  'status.final.subtitle': 'بازدید از این بیمار به پایان رسیده است.',

  'hospital.call': 'تماس بگیرید',
  'hospital.directions': 'درخواست دستورالعمل',
  'feedback.error':
    'خطایی در ارسال فرم رخ داده است. لطفاً مجدداً تلاش کنید. در صورت ادامه مشکل، با پرسنل خدمات پشتیبانی تماس بگیرید.',
  'feedback.share.question': 'آیا مایلید تجربه خود را با دیگران به اشتراک بگذارید؟',
  'feedback.share.google': 'یک نظر در گوگل بگذارید',
  'feedback.share.yelp': 'ما را در یلپ امتیاز دهید!',
  'feedback.share.skip': 'رد کردن',
  'feedback.suggestion.question':
    'آیا می خواهید نظری بگذارید یا به ما بگویید چگونه می توانیم تجربه شما را بهبود بخشیم؟',
  'feedback.send': 'ارسال نظر',
  'feedback.success.title': 'بسیار ممنون!',
  'feedback.success.text': 'پیشنهاد شما ارسال شد.',
  'rating.instructions': 'تجربه خود را ارزیابی کنید',
  'rating.thanks': 'بسیار ممنون!',
  'This field is required': 'این فیلد الزامی است',
  'Please fill in the required fields': 'لطفاً فیلدهای ضروری را پر کنید',
};

export default fa;
