import React, { useContext, useMemo, useState } from 'react';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import FormContext from '../FormContext';
import makeName from '../makeName';
import { useIntl } from 'react-intl';
import { USA_STATES } from '../utils/usaStates';
import { FormControl, FormHelperText, MenuItem, Select, TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { FieldType, validatorFn, ValidatorType } from '../utils/validators/validateRequired';
import ValidationWrapper from './ValidationWrapper';
import TranslatedMessage from '../TranslatedMessage';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

const CityStateZipInput = ({ validators }) => {
  const intl = useIntl();
  const [error, setError] = useState(null);
  const { name, value, onChange } = useContext(FormContext);

  const cityName = makeName(name, 'city');
  const stateName = makeName(name, 'state');
  const zipCodeName = makeName(name, 'zipCode');

  const city = value?.cityStateZip?.city || '';
  const state = value?.cityStateZip?.state || '';
  const zipCode = value?.cityStateZip?.zipCode || '';
  const obj = useMemo(() => ({ city, state, zipCode }), [city, state, zipCode]);
  const rules = validators ? validators.map(type => validatorFn[type]?.(FieldType.CityStateZip) ?? (() => '')) : [];
  const required = !!validators?.find(e => e === ValidatorType.isRequired);

  const handleChange = name => e => {
    let nextInputValue;
    if (name === 'zipCode') {
      nextInputValue = e.target.value.replace(/\D/g, '');
    } else {
      nextInputValue = e.target.value.replace(/([.*+?^=!:${}()|[\]/\\])/g, '');
    }
    const nextValue = set(value ? cloneDeep(value) : {}, ['cityStateZip', name], nextInputValue);
    const ok = (name, length) => get(nextValue, ['cityStateZip', name], '').length >= length;
    const done = ok('city', 1) && ok('state', 1) && ok('zipCode', 1);
    onChange(cloneDeep(set(nextValue, 'progress', done ? 1 : 0)));
  };

  return (
    <ValidationWrapper value={obj} rules={rules} error={error} setError={setError}>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              label={intl.formatMessage({ id: 'questionnaire.csz.city', defaultMessage: 'City' })}
              type="text"
              id={cityName}
              name={cityName}
              value={city}
              onChange={handleChange('city')}
              required={required}
              error={!!error}
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="outlined" style={{ width: '100%' }} required={required} error={!!error}>
              <InputLabel id={stateName}>
                {intl.formatMessage({ id: 'questionnaire.csz.state', defaultMessage: 'State' })}
              </InputLabel>
              <Select labelId="state" id={stateName} value={state} onChange={handleChange('state')}>
                {USA_STATES.map(state => (
                  <MenuItem value={state.name} key={state.name}>
                    {state.abbreviation}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="text"
              variant="outlined"
              label={intl.formatMessage({ id: 'questionnaire.csz.zip', defaultMessage: 'Zip Code' })}
              id={zipCodeName}
              name={zipCodeName}
              value={zipCode}
              onChange={handleChange('zipCode')}
              required={required}
              error={!!error}
              fullWidth={true}
            />
          </Grid>
        </Grid>
        {!!error && (
          <FormHelperText style={{ color: 'red' }}>
            <TranslatedMessage message={error} />
          </FormHelperText>
        )}
      </Box>
    </ValidationWrapper>
  );
};

CityStateZipInput.defaultValue = { cityStateZip: {}, progress: 0 };

export default CityStateZipInput;
