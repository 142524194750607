import React from 'react';
import Header from './Header';
import PatientStatusDetails from './PatientStatusDetails';
import Box from '@material-ui/core/Box';
import { Patient } from '../../types/patient';

interface ActivePatientProps {
  patient: Patient;
  hospital: any;
}

const ActivePatient = ({ patient, hospital }: ActivePatientProps) => (
  <React.Fragment>
    <Box mb={3}>
      <Header patient={patient} isCaretaker={true} />
    </Box>
    <PatientStatusDetails patient={patient} hospital={hospital} />
  </React.Fragment>
);

export default ActivePatient;
