import React, {createContext, useContext} from 'react';

export type ProcedureContextType = {
  anesthesiaType?: string;
  patientAge?: number;
  patientSex?: string;
  physicianSpecialties?: string;
  physicianCategories?: string;
};

export const ProcedureContext = createContext<ProcedureContextType | null>(null);

export const useProcedureContext = () => {
  const context = useContext(ProcedureContext);
  if (context === undefined) {
    throw new Error('useProcedureContext must be used within a ProcedureProvider');
  }
  return context;
};


