import React, { FC, useCallback } from 'react';
import { IntlProvider } from 'react-intl';
import LocaleProvider, { LocaleContext } from './LocaleProvider';
import { Locale } from '../types/localization';

interface LocalizationProviderProps {
  locales: Locale[];
  children: React.ReactNode;
}

const LocalizationProvider: FC<LocalizationProviderProps> = ({ locales, children }) => (
  <LocaleProvider locales={locales}>
    <LocaleContext.Consumer>
      {useCallback(
        ({ availableLocales, selectedLocale }) => (
          <IntlProvider
            locale={selectedLocale.id.toString()}
            defaultLocale={(availableLocales[0] || locales[0]).id.toString()}
            messages={selectedLocale.messages}
          >
            {children}
          </IntlProvider>
        ),
        [locales, children]
      )}
    </LocaleContext.Consumer>
  </LocaleProvider>
);

export default LocalizationProvider;
