import { format } from 'date-fns';
import PatientStatus from '../../enums/PatientStatus';
import { PatientLogEvent } from '../../types/patient';

export const optionalDateFormat = (date: any) => (date ? format(date, 'MM/DD/YYYY hh:mm A') : date);

export const getCurrentStatus = (events: PatientLogEvent) => {
  if (events.ableToVisitPOSTOPAt) {
    return PatientStatus.ABLE_TO_VISIT_POSTOP;
  } else if (events.ableToVisitPACUAt) {
    return PatientStatus.ABLE_TO_VISIT_PACU;
  } else if (events.recoveryAt) {
    return PatientStatus.PACU;
  } else if (events.orAt) {
    return PatientStatus.IN_OR;
  } else if (events.preOpAt) {
    return PatientStatus.PRE_OP;
  } else if (events.postOpAt) {
    return PatientStatus.IN_POST_OP;
  }
  return PatientStatus.ADMITTED;
};

/**
 * @param {{}} events
 * @return {{ADMITTED: boolean, PRE_OP: boolean, IN_OR: boolean, PACU: boolean, ABLE_TO_VISIT: boolean}}
 */
export const getStatusObject = (events: PatientLogEvent = {}) => ({
  ADMITTED: Boolean(events && events.admittedAt),
  PRE_OP: Boolean(events && events.preOpAt),
  IN_OR: Boolean(events && events.orAt),
  POST_OP: Boolean(events && events.postOpAt),
  PACU: Boolean(events && events.recoveryAt),
  ABLE_TO_VISIT_PACU: Boolean(events && events.ableToVisitPACUAt),
  ABLE_TO_VISIT_POSTOP: Boolean(events && events.ableToVisitPOSTOPAt),
});

/**
 * @param events
 * @return {{ADMITTED: string, PRE_OP: string, IN_OR: string, PACU: string, ABLE_TO_VISIT: string}}
 */
export const getStatusTimestamps = (events: PatientLogEvent = {}) => ({
  ADMITTED: events && events.admittedAt ? optionalDateFormat(events.admittedAt) : '',
  PRE_OP: events && events.preOpAt ? optionalDateFormat(events.preOpAt) : '',
  IN_OR: events && events.orAt ? optionalDateFormat(events.orAt) : '',
  PACU: events && events.recoveryAt ? optionalDateFormat(events.recoveryAt) : '',
  POST_OP: events && events.recoveryAt ? optionalDateFormat(events.postOpAt) : '',
  ABLE_TO_VISIT_PACU: events && events.ableToVisitPACUAt ? optionalDateFormat(events.ableToVisitPACUAt) : '',
  ABLE_TO_VISIT_POSTOP: events && events.ableToVisitPOSTOPAt ? optionalDateFormat(events.ableToVisitPOSTOPAt) : '',
});
