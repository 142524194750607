import { FormType, QuestionnaireType } from '../../../types/Questionnaire';

export const getFormType = (type: QuestionnaireType): FormType => {
  switch (type) {
    case QuestionnaireType.PreOpChart:
      return FormType.PreOpChartForm;
    case QuestionnaireType.OrChart:
      return FormType.OrChartForm;
    case QuestionnaireType.PacuChart:
      return FormType.PacuChartForm;
    case QuestionnaireType.Intake:
      return FormType.InTakeForm;
    case QuestionnaireType.PreOp:
      return FormType.PreOpForm;
    default:
      return FormType.PreOpForm;
  }
};
