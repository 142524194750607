import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import {makeStyles} from '@material-ui/styles';
import {CircularProgressWithLabel} from '../../components/CircularProgressWithLabel';
import Questionnaire from './Questionnaire';
import QuestionnaireStartPage from '../../sections/Questionnaire/QuestionnaireStartPage';
import QuestionnaireStatus from '../../enums/QuestionnaireStatus';
import QuestionnaireSuccessState from '../../sections/Questionnaire/QuestionnaireSuccessState';
import DocumentSignature from '../../sections/Questionnaire/documentSignature/DocumentSignature';
import DocumentUpload from '../../sections/Questionnaire/documentUpload/DocumentUpload';
import DocumentUploadPreOp from '../../sections/Questionnaire/documentUpload/DocumentUploadPreOp';
import DocumentUploadIntake from '../../sections/Questionnaire/documentUpload/DocumentUploadIntake';
import {compact, filter, findIndex} from 'lodash';
import get from 'lodash/fp/get';
import Unauthorized from '../Unauthorized';
import withAuthentication from '../../components/authentication/withAuthentication';
import {useMutation, useQuery} from 'react-apollo-hooks';
import {
  editQuestionnaireAnswers,
  myQuestionnaireAnswers,
  questionnaireAnswers,
  submitQuestionnaireAnswers,
} from '../../graphql/schema/questionnaire';
import {
  forms as formsQuery,
  signature as signatureQuery,
  uploadFormTypesForProcedure,
  uploadFormTypesForProcedurePreOp,
  uploadFormTypesForProcedureIntake
} from '../../graphql/schema/form';
import renderFormNode from '../../components/Questionnaire/renderFormNode';
import {LocaleId} from '../../types/localization';
import {useSpecificLocales} from '../../components/LocaleProvider';
import {useIntl} from 'react-intl';
import withDateOfBirthAuthentication from '../../components/authentication/withDateOfBirthAuthentication';
import {getFormType} from '../../components/Questionnaire/utils/util';
import config from '../../config';
import {NurseProcedureContextProvider} from "../../components/Questionnaire/NurseProcedureContext";
import {PatientProcedureContextProvider} from "../../components/Questionnaire/PatientProcedureContext";
import {getQuestionnaireProgress, shouldRenderSection} from "../../components/Questionnaire/utils/shouldRenderSection";
import {useProcedureContext} from "../../components/Questionnaire/ProcedureContext";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    [theme.breakpoints.up('md')]: {
      top: 0,
      bottom: 'auto',
    },
  },
  step: {
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(0),
    },
  },
}));

const LabeledProgress = ({progress, label}) => (
  <Box display="flex" alignItems="center" alignSelf="center" flexDirection="column">
    <CircularProgressWithLabel {...progress} />
    <label>{label}</label>
  </Box>
);

const Navigation = ({tab, navigateTo, progress, tabs}) => {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    window.scrollTo(0, 0);
    navigateTo(newValue);
  };

  return (
    <AppBar position={'fixed'} className={classes.appBar}>
      <Paper square className={classes.root}>
        <Tabs value={tab} onChange={handleChange} variant="fullWidth" aria-label={tab}>
          {tabs.map(({tab, label}) => (
            <Tab key={tab} label={<LabeledProgress label={label} progress={progress[tab]}/>} value={tab}/>
          ))}
        </Tabs>
      </Paper>
    </AppBar>
  );
};

const signatureProgress = forms => {
  const value = (forms.filter(_ => _.isChecked).length * 100) / forms.length;
  return {value, label: formatPercent(value)};
};

const questionnaireProgress = (value, weight) => {
  const percent = ((get('progress')(value) || 0) / weight) * 100;
  return {value: percent >= 100 ? 100 : percent, label: formatPercent(percent >= 100 ? 100 : percent)};
};

const formatPercent = value => `${Math.round(value)}%`;

const uploadedProgress = forms => {
  const length = forms.length;
  const value = forms.filter(item => get('uploads')(item).length > 0).length;
  const progress = (value * 100) / length;
  return {value: progress, label: `${value} / ${length}`};
};

const App = ({type, questions, answers, procedureId, defaultValues, ...props}) => {
  const intl = useIntl();

  const [step, setCurrentStep] = useState(QuestionnaireStatus.WELCOME);
  const classes = useStyles();

  const setStep = useCallback(step => {
    window.scrollTo(0, 0);
    setCurrentStep(step);
  }, []);

  /// Document Signature state management
  const {
    loading,
    data: {procedureForms},
  } = useQuery(formsQuery, {variables: {procedureId, formType: getFormType(type)}});
  const [formsForSigning, setFormsForSigning] = useState([]);
  const hasDocumentsToSign = formsForSigning.length > 0;
  const forms = useMemo(() => procedureForms || [], [procedureForms]);

  // TODO: refactor this into separate object for form state
  useEffect(() => {
    setFormsForSigning(forms.filter(_ => _.shouldSign).map(row => ({...row, isChecked: row.isSigned})));
  }, [forms]);

  /// Questionnaire state management
  const originalAnswers = useMemo(() => JSON.parse(answers), [answers]);
  const [questionnaireValue, onQuestionnaireChange] = useState(originalAnswers);
  const procedureContext = useProcedureContext();
  const [formFields, formWeight] = useMemo(() => {
    const rootNode = JSON.parse(questions);
    return [renderFormNode(rootNode), getQuestionnaireProgress(procedureContext, rootNode?.props?.sections || [])];
  }, [questions]);

  /// Document Upload state management
  let uploadFilter = '';
  const getUploadType = (type) => {
    switch (type) {
      case 'PreOp':
        uploadFilter = 'uploadFormTypesForProcedurePreOp'
        return uploadFormTypesForProcedurePreOp;
      case 'InTakeForm':
        uploadFilter = 'uploadFormTypesForProcedureIntake'
        return uploadFormTypesForProcedureIntake;
      default:
        uploadFilter = 'uploadFormTypesForProcedure'
        return uploadFormTypesForProcedure;
    }
  };

  const uploadType = getUploadType(type);
  const {data: formQuery} = useQuery(uploadType, {variables: {procedureId}});
  const uploadForms = useMemo(
    () => filter(get(uploadFilter)(formQuery) || [], item => item.shouldSign),
    [formQuery]
  );
  const hideUploads = Boolean(new URLSearchParams(window.location.search).get('hideUploads'));
  const hasDocumentsForUpload = !hideUploads && uploadForms.length > 0;

  /// Signature query
  const {
    data: {signature: existingSignature},
  } = useQuery(signatureQuery, {
    variables: {procedureId, formType: getFormType(type)},
    fetchPolicy: 'network-only',
  });

  const progress = useMemo(
    () => ({
      [QuestionnaireStatus.QUESTIONNAIRE]: questionnaireProgress(questionnaireValue, formWeight),
      [QuestionnaireStatus.SIGNATURE]: signatureProgress(formsForSigning),
      [QuestionnaireStatus.DOCUMENT_UPLOAD]: uploadedProgress(uploadForms),
    }),
    [formWeight, formsForSigning, questionnaireValue, uploadForms]
  );

  const navigation = useMemo(
    () =>
      compact([
        {
          tab: QuestionnaireStatus.QUESTIONNAIRE,
          label: intl.formatMessage({id: 'questionnaire.tabs.questionnaire', defaultMessage: 'Questionnaire'}),
        },
        hasDocumentsForUpload
          ? {
            tab: QuestionnaireStatus.DOCUMENT_UPLOAD,
            label: intl.formatMessage({id: 'questionnaire.tabs.docs', defaultMessage: 'Upload'}),
          }
          : undefined,
        hasDocumentsToSign
          ? {
            tab: QuestionnaireStatus.SIGNATURE,
            label: intl.formatMessage({id: 'questionnaire.tabs.signature', defaultMessage: 'Sign'}),
          }
          : undefined,
      ]),
    [hasDocumentsForUpload, hasDocumentsToSign, intl]
  );

  const QuestionnaireStatusFlow = compact([
    QuestionnaireStatus.WELCOME,
    QuestionnaireStatus.QUESTIONNAIRE,
    hasDocumentsForUpload ? QuestionnaireStatus.DOCUMENT_UPLOAD : undefined,
    hasDocumentsToSign ? QuestionnaireStatus.SIGNATURE : undefined,
    QuestionnaireStatus.REVIEW,
  ]);

  const moveToDataCollection = useCallback(() => setStep(QuestionnaireStatus.QUESTIONNAIRE), [setStep]);

  const moveBack = useCallback(() => {
    const idx = findIndex(QuestionnaireStatusFlow, _ => _ === step);
    setStep(QuestionnaireStatusFlow[idx - 1]);
  }, [QuestionnaireStatusFlow, setStep, step]);

  const moveForward = useCallback(() => {
    const idx = findIndex(QuestionnaireStatusFlow, _ => _ === step);
    setStep(QuestionnaireStatusFlow[idx + 1]);
  }, [QuestionnaireStatusFlow, setStep, step]);

  const commonProps = useMemo(
    () => ({...props, procedureId, loading, onBack: moveBack, onDone: moveForward}),
    [loading, moveBack, moveForward, procedureId, props]
  );

  const DocumentUploadComponent = useMemo(() => {
    switch (type) {
      case 'PreOp':
        return DocumentUploadPreOp;
      case 'InTakeForm':
        return DocumentUploadIntake;
      default:
        return DocumentUpload;
    }
  }, [type]);

  const DataCollection = (
    <Box className={classes.step}>
      <Navigation tab={step} navigateTo={setStep} progress={progress} tabs={navigation}/>
      {step === QuestionnaireStatus.QUESTIONNAIRE && (
        <Questionnaire
          {...commonProps}
          type={type}
          formFields={formFields}
          value={questionnaireValue}
          defaultValues={defaultValues}
          onChange={onQuestionnaireChange}
        />
      )}
      {step === QuestionnaireStatus.SIGNATURE && (
        <DocumentSignature type={type} {...commonProps} forms={formsForSigning} setForms={setFormsForSigning}/>
      )}
      {step === QuestionnaireStatus.DOCUMENT_UPLOAD && <DocumentUploadComponent {...commonProps} forms={uploadForms}/>}
    </Box>
  );

  switch (step) {
    case QuestionnaireStatus.WELCOME:
      return (
        <QuestionnaireStartPage
          intake={type === 'InTakeForm'}
          onAccept={moveToDataCollection}
          procedureId={procedureId}
        />
      );
    case QuestionnaireStatus.QUESTIONNAIRE:
      return DataCollection;
    case QuestionnaireStatus.SIGNATURE:
      return DataCollection;
    case QuestionnaireStatus.DOCUMENT_UPLOAD:
      return DataCollection;
    case QuestionnaireStatus.REVIEW:
      return (
        <QuestionnaireSuccessState
          onBack={moveToDataCollection}
          hasDocumentsForUpload={hasDocumentsForUpload}
          hasSignature={existingSignature}
          progress={progress[QuestionnaireStatus.QUESTIONNAIRE].value}
        />
      );
    default:
      return null;
  }
};

const extractLocales = questionsJson => {
  try {
    const questions = JSON.parse(questionsJson || '{}');

    if (questions.type === 'Sections') {
      const locales = questions.props.locales;

      if (locales) {
        return locales.map(({id, messages}) => ({id: LocaleId.fromString(id), messages}));
      }
    }

    return undefined;
  } catch (e) {
    console.warn('Unable to extract questionnaire locales', e);
    return undefined;
  }
};

const MyLoadedQuestionnaire = ({id, selectData, useQuestionnaireMutation, onBack, onDone, data}) => {
  const {type, questions, answers, procedureId, defaultValues} = useMemo(() => selectData(data), [data, selectData]);

  const locales = useMemo(() => extractLocales(questions) || [], [questions]);

  useSpecificLocales(locales);

  return (
    <App
      id={id}
      procedureId={procedureId}
      type={type}
      questions={questions}
      answers={answers}
      defaultValues={JSON.parse(defaultValues) || {}}
      useQuestionnaireMutation={useQuestionnaireMutation}
      onBack={onBack}
      onDone={onDone}
    />
  );
};

// DEPRECATED: This Management interface is copied over from the last implementation as is
// We should separate the concerns of creating questionnaire data collection step props and Questionnaire App authentication
const MyQuestionnaire = ({useQuestionnaireQuery, onExpired, ...props}) => {
  const {loading, error, data} = useQuestionnaireQuery();

  if ((get('message')(error) || '').indexOf('Unauthorized') > 0) {
    onExpired && onExpired();
    return null;
  }

  if (loading) {
    return null;
  }

  if (error && error.message === 'GraphQL error: Selected entity does not exist') {
    return <Unauthorized/>;
  }

  if (error) {
    return null;
  }

  if (!data) {
    return null;
  }

  return <MyLoadedQuestionnaire {...props} data={data}/>;
};

const AdminQuestionnaire = MyQuestionnaire;

const PatientQuestionnaire = withDateOfBirthAuthentication(withAuthentication(MyQuestionnaire));

export const QuestionnaireContext = React.createContext({isAdmin: false});

const QuestionnaireAppWithId = ({onBack, onDone, id}) => {
  const selectData = useMemo(() => get('questionnaireAnswers'), []);
  const useQuestionnaireQuery = useCallback(
    () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useQuery(questionnaireAnswers, {
        variables: {
          id: parseInt(id, 10),
        },
      }),
    [id]
  );

  const useQuestionnaireMutation = useCallback(
    () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useMutation(editQuestionnaireAnswers, {
        refetchQueries: [{query: questionnaireAnswers, variables: {id: parseInt(id, 10)}, skip: !id}],
      }),
    [id]
  );

  const questionnaireContext = useMemo(() => ({isAdmin: true}), []);

  return (
    <QuestionnaireContext.Provider value={questionnaireContext}>
      <NurseProcedureContextProvider questionnaireExchangeId={id}>
        <AdminQuestionnaire
          id={parseInt(id, 10)}
          selectData={selectData}
          useQuestionnaireQuery={useQuestionnaireQuery}
          useQuestionnaireMutation={useQuestionnaireMutation}
          onBack={onBack}
          onDone={onDone}
        />
      </NurseProcedureContextProvider>
    </QuestionnaireContext.Provider>
  );
};

const QuestionnaireAppWithoutId = ({onBack, onDone}) => {
  const selectData = useMemo(() => get('myQuestionnaireAnswers'), []);
  const useQuestionnaireQuery = useCallback(
    () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useQuery(myQuestionnaireAnswers, {
        variables: {
          patientDateOfBirth: localStorage.getItem('patientDateOfBirth'),
        },
      }),
    []
  );
  const useQuestionnaireMutation = useCallback(
    () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useMutation(submitQuestionnaireAnswers, {
        refetchQueries: [
          {
            query: myQuestionnaireAnswers,
            variables: {patientDateOfBirth: localStorage.getItem('patientDateOfBirth')},
          },
        ],
      }),
    []
  );

  const questionnaireContext = useMemo(() => ({isAdmin: false}), []);

  return (
    <QuestionnaireContext.Provider value={questionnaireContext}>
      <PatientProcedureContextProvider>
        <PatientQuestionnaire
          selectData={selectData}
          useQuestionnaireQuery={useQuestionnaireQuery}
          useQuestionnaireMutation={useQuestionnaireMutation}
          onBack={onBack}
          onDone={onDone}
        />
      </PatientProcedureContextProvider>
    </QuestionnaireContext.Provider>
  );
};

const QuestionnaireApp = ({match, ...props}) => {
  const id = get('params.id')(match);

  useEffect(() => {
    const handleActivity = () => {
      window.parent.postMessage({event: 'Activity'}, config.appURL);
    };

    document.addEventListener('mousedown', handleActivity);
    document.addEventListener('mouseup', handleActivity);
    document.addEventListener('keydown', handleActivity);
    document.addEventListener('keyup', handleActivity);

    return () => {
      document.removeEventListener('mousedown', handleActivity);
      document.removeEventListener('mouseup', handleActivity);
      document.removeEventListener('keydown', handleActivity);
      document.removeEventListener('keyup', handleActivity);
    };
  }, []);

  if (id) {
    return <QuestionnaireAppWithId {...props} id={id}/>;
  } else {
    return <QuestionnaireAppWithoutId {...props} />;
  }
};

export default QuestionnaireApp;
