import { Messages } from '../types/localization';

const en: Messages = {
  'homepage.instructions': 'Please enter your PIN',
  'homepage.error.pin': 'PIN is not valid, please try again.',
  'homepage.error.unexpected': 'Unexpected error ocurred, please try again later.',
  'homepage.helper': 'You can find the PIN in the text message you have received from the surgical center.',
  'status.patient': 'Patient',
  'status.physician': 'Physician',
  'status.status': 'Status',
  'status.time': 'Time',
  'status.admitted': 'Admitted',
  'status.preparing-for-surgery': 'Preparing for Surgery',
  'status.in-surgery': 'In Surgery',
  'status.in-recovery': 'In Recovery',
  'status.in-icu': 'In ICU',
  'status.in-postop': 'In Postop',
  'status.able-to-visit': 'Able to visit',
  'status.final.title': 'This patient’s visit has concluded.',
  'status.final.subtitle': 'We appreciate the opportunity to provide care.',
  'hospital.call': 'Call',
  'hospital.directions': 'Directions',
  'feedback.error':
    'There was an error submitting the form. Please, try again. If the problem persists, contact the help desk staff.',
  'feedback.share.question': 'Do you want to share your experience with others?',
  'feedback.share.google': 'Leave a review on Google',
  'feedback.share.yelp': 'Rate us on Yelp!',
  'feedback.share.skip': 'Skip',
  'feedback.suggestion.question':
    'Would you like to leave a comment or tell us how we could have improved your experience?',
  'feedback.send': 'Send Feedback',
  'feedback.success.title': 'Thank you!',
  'feedback.success.text': 'Your suggestion has been sent.',
  'rating.instructions': 'Rate Your Experience',
  'rating.thanks': 'Thank you!',
  'This field is required': 'This field is required',
  'Please fill in the required fields': 'Please fill in the required fields',
  'caretaker.ready-to-pick-up': 'Patient is ready to be picked up!',
  'caretaker.type-here': 'Type here...',
  'caretaker.confirm': 'Confirm',
  'caretaker.thanks': 'Thank you!',
  'caretaker.successfully-sent': 'Your message was sent successfully.',
};

export default en;
