import React from 'react';
import styled from 'styled-components';

const LabelDiv = styled.label`
  display: block;
  color: ${({ theme }) => theme.smallLabelColor};
  opacity: 0.45;
  font-size: 1.35em;
  line-height: 1.75rem;
  margin-bottom: 6px;
`;

const SmallLabelThemeSupport = ({ message }) => <LabelDiv>{message}</LabelDiv>;

export default SmallLabelThemeSupport;
