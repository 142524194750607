import React, { Fragment, VFC } from 'react';
import { Box, Button, Container, fade, Icon, Paper, Typography, Zoom } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { ClipLoader } from 'react-spinners';
import { OtherSpecialtyLabel, PrimarySpecialtyColors, PrimarySpecialtyLabel, TitleLabel } from '../types/StaffMember';
import { OpenPosition, StaffShiftLog, StaffShiftState } from '../types/OpenPosition';
import { useMutation, useQuery } from 'react-apollo-hooks';
import { getOpenPositions, staffMemberApprove, staffMemberReject } from '../graphql/schema/staff';
import formatTimeRange from '../utils/formatTimeRange';
import { getGMapsLink } from '../sections/PatientStatus/HospitalInformation';
import { format } from 'date-fns';
import { atcb_init } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';
import { hospital } from '../graphql/schema/familyMember';
import { Action } from '../sections/Questionnaire/QuestionnaireStartPage';
//@ts-ignore
import styled from 'styled-components';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  status: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  statusConfirmed: {
    backgroundColor: fade(theme.palette.success.main, 0.1),
    color: theme.palette.success.main,
  },
  statusRejected: {
    backgroundColor: fade(theme.palette.error.main, 0.1),
    color: theme.palette.error.main,
  },
  statusReschedule: {
    backgroundColor: fade(theme.palette.info.main, 0.1),
    color: theme.palette.info.main,
  },
  actions: {
    '& > *': {
      marginTop: theme.spacing(1),
    },
  },
  root: {
    height: 180,
  },
  paper: {
    margin: theme.spacing(1),
  },
  svg: {
    width: 100,
    height: 100,
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
}));

interface StatusCardProps {
  openPosition: OpenPosition;
  askQuestion: boolean;
  accepted: boolean;
  rejected: boolean;
  location?: string;
}

const getHiringManagerInfo = (logs: StaffShiftLog[]) => (logs ? logs[logs.length - 1] : undefined);

const HiringManager: VFC<{ log?: StaffShiftLog }> = ({ log }) =>
  log?.userName || log?.userPhoneNumber ? (
    <>
      {log?.userName && (
        <Typography variant={'body2'} color={'textSecondary'}>
          Hiring Manager: {log.userName}
        </Typography>
      )}
      {log?.userPhoneNumber && (
        <Typography variant={'body2'} color={'textSecondary'}>
          {' '}
          {log.userPhoneNumber}
        </Typography>
      )}
    </>
  ) : null;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  > * + * {
    margin-left: 0.75em;
  }
`;

const BasicInformation: VFC<{ openPosition: OpenPosition }> = ({ openPosition }) => (
  <div>
    <Typography
      color="textPrimary"
      variant="h5"
      style={{
        marginBottom: -20,
      }}
    >
      <Box fontWeight="fontWeightMedium" display="inline">
        {`${openPosition.date && format(openPosition.date, 'MMM DD YYYY')}`}
      </Box>
    </Typography>
    <br />
    <Typography
      color="textPrimary"
      variant="h5"
      style={{
        marginBottom: -20,
      }}
    >
      <Box fontWeight="fontWeightMedium" display="inline">
        {formatTimeRange(openPosition?.from, openPosition?.to)}
      </Box>
    </Typography>
    <br />
    {openPosition?.description?.title && (
      <Typography variant={'body1'} color={'textSecondary'}>
        Title: {(TitleLabel as any)[openPosition?.description?.title]}
      </Typography>
    )}
    {openPosition?.description?.primarySpecialty && (
      <Typography variant={'body2'} color={'textSecondary'}>
        Speciality: &nbsp;
        <span style={{ color: (PrimarySpecialtyColors as any)[openPosition?.description?.primarySpecialty] }}>
          ●&nbsp;
        </span>
        {(PrimarySpecialtyLabel as any)[openPosition?.description?.primarySpecialty]}
      </Typography>
    )}
    {!!openPosition?.description?.otherSpecialties?.length && (
      <Typography variant={'body2'} color={'textSecondary'}>
        {`Other ${openPosition?.description?.otherSpecialties.length === 1 ? 'speciality' : 'specialities'}:`}{' '}
        {(openPosition?.description?.otherSpecialties || []).map(e => (OtherSpecialtyLabel as any)[e]).join(', ')}
      </Typography>
    )}
    {!!openPosition?.description?.description && (
      <Typography variant={'body2'} color={'textSecondary'}>
        Description: {openPosition?.description?.description}
      </Typography>
    )}
    <HiringManager log={getHiringManagerInfo(openPosition.logs)} />
  </div>
);

const getCalendarOptions = () => {
  let calendarOptions = `"Apple", "Google"`;
  const isSafari =
    navigator.vendor.match(/apple/i) &&
    !navigator.userAgent.match(/crios/i) &&
    !navigator.userAgent.match(/fxios/i) &&
    !navigator.userAgent.match(/Opera|OPT\//);
  if (/android/i.test(navigator.userAgent)) {
    calendarOptions = `"Google"`;
  } else if (!isSafari && 'GestureEvent' in window) {
    calendarOptions = `"Google"`;
  }

  return calendarOptions;
};

const AcceptedShift: VFC<{ openPosition: OpenPosition; location?: string }> = ({ openPosition, location }) => {
  React.useEffect(atcb_init, []);
  const name = `"Procedure at ${openPosition.staffShiftHospitalName}"`;
  const description = `"Assignment at ${openPosition.staffShiftHospitalName}"`;
  const date = `"${openPosition.date}"`;
  const from = `"${openPosition?.from || '06:00'}"`;
  const to = `"${openPosition.to || '18:00'}"`;
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZone = `"${tz}"`;
  const tempLocation = `"${location}"`;

  const calendarOptions = getCalendarOptions();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        style={{
          marginBottom: 10,
        }}
      >
        <Icon
          style={{
            marginBottom: -5,
          }}
        >
          check
        </Icon>{' '}
        Accepted
      </Typography>
      <BasicInformation openPosition={openPosition} />
      <div className="atcb">
        {'{'}
        "name":{name}, "description":{description}, "startDate":{date}, "endDate":{date}, "startTime":{from}, "endTime":
        {to}, "location":{tempLocation}, "label":"Add to Calendar", "options":[{calendarOptions}], "timeZone":
        {timeZone}, "iCalFileName":"Reminder-Event"
        {'}'}
      </div>
    </div>
  );
};

const RejectedShift: VFC<{ openPosition: OpenPosition; location?: string }> = ({ openPosition }) => (
  <div>
    <Typography
      style={{
        marginBottom: 10,
      }}
    >
      <Icon
        style={{
          marginBottom: -5,
        }}
      >
        close
      </Icon>{' '}
      Rejected
    </Typography>
    <Typography
      color="textSecondary"
      align="center"
      style={{
        marginBottom: 10,
      }}
    >
      Thank you for letting us know.
    </Typography>
    <HiringManager log={getHiringManagerInfo(openPosition.logs)} />
  </div>
);
const StatusCard = ({ openPosition, askQuestion, accepted, rejected, location }: StatusCardProps) => {
  const classes = useStyles();

  return (
    <Paper
      elevation={0}
      className={clsx(classes.status, {
        [classes.statusConfirmed]: accepted,
        [classes.statusReschedule]: !accepted && !rejected,
        [classes.statusRejected]: rejected,
      })}
    >
      <Box p={2} textAlign="center" display="flex" flexDirection="column" alignItems="center">
        <Box display="flex" alignItems="center" mb={1}>
          {askQuestion && <BasicInformation openPosition={openPosition} />}
          {accepted && <AcceptedShift openPosition={openPosition} location={location} />}
          {rejected && <RejectedShift openPosition={openPosition} />}
        </Box>
      </Box>
    </Paper>
  );
};

const ApproveAssignmentPage = () => {
  const classes = useStyles();

  const { data, loading } = useQuery(getOpenPositions);
  const { data: hospitalData, loading: hospitalLoading } = useQuery(hospital);

  const openPosition: OpenPosition = data?.staffMemberOpenPosition;
  const contact = hospitalData?.patientHospital?.contact;
  const address = hospitalData?.patientHospital?.address;
  const approveAssigment = useMutation(staffMemberApprove);
  const rejectAssigment = useMutation(staffMemberReject);

  const accepted = openPosition?.state === StaffShiftState.ApprovedByStaffMember;
  const rejected = openPosition?.state === StaffShiftState.Opened;
  const answered = accepted || rejected;
  const askQuestion = !answered;

  const approve = async () => {
    await approveAssigment({ refetchQueries: [{ query: getOpenPositions }] });
  };

  const reject = async () => {
    await rejectAssigment({ refetchQueries: [{ query: getOpenPositions }] });
  };

  if (loading || hospitalLoading) {
    return (
      <Container maxWidth="sm" className={classes.container} style={{ justifyContent: 'center', alignItems: 'center' }}>
        <ClipLoader />
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Box>
        <Typography color="textPrimary" align="center" style={{ marginTop: 12 }} variant="h3">
          {openPosition?.staffShiftHospitalName}
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 5,
          }}
        >
          <Actions>
            {contact && (
              <Action href={`tel:${contact}`}>
                <span className="material-icons">phone</span>
                <div>Call {contact}</div>
              </Action>
            )}
            {address && (
              <Action href={getGMapsLink(address)} target="_blank" rel="noopener noreferrer">
                <span className="material-icons">directions</span>
                <div>Directions</div>
              </Action>
            )}
          </Actions>
        </div>

        <Box>
          {askQuestion && (
            <Typography variant="h5" align="center">
              {`You have been assigned a shift at ${openPosition.staffShiftHospitalName}`}
            </Typography>
          )}
          {accepted && (
            <Fragment>
              <Typography gutterBottom variant="h4" align="center">
                Thank you!
              </Typography>
              <Typography color="textSecondary" align="center">
                Your shift is confirmed
              </Typography>
            </Fragment>
          )}
          {rejected && (
            <Fragment>
              <Typography color="textSecondary" align="center">
                Thank you for letting us know.
              </Typography>
            </Fragment>
          )}
        </Box>
      </Box>

      {answered && (
        <Typography align="center" variant="subtitle1" color="textSecondary">
          No further action needed. Feel free to close this tab.
        </Typography>
      )}

      <Zoom in={true} unmountOnExit>
        <StatusCard
          askQuestion={askQuestion}
          accepted={accepted}
          rejected={rejected}
          openPosition={openPosition}
          location={address}
        />
      </Zoom>

      {askQuestion && (
        <Box className={classes.actions} py={2} mb={8}>
          <Button color="primary" size="large" onClick={approve} fullWidth>
            Accept
          </Button>
          <Button fullWidth size="large" onClick={reject}>
            Reject
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default ApproveAssignmentPage;
