import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import LocalizationProvider from './components/LocalizationProvider';
import { locales } from './components/LocaleProvider';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

if (process.env.NODE_ENV === 'production') {
  const environments = {
    'https://care.testing.ospitek.com': ['Testing', 1.0],
    'https://care.view1.ospitek.com': ['View1', 0.1],
    'https://care.ospitek.com': ['View', 0.1],
  };

  const [environment, tracesSampleRate] = environments[process.env.PUBLIC_URL] || [];

  if (environment) {
    Sentry.init({
      dsn: 'https://cd63409cd0294ccbb44e3b1182fae586@o1065753.ingest.sentry.io/6057910',
      environment,
      tracesSampleRate,
      integrations: [new Integrations.BrowserTracing()],
    });
  }
}

if (process.env.NODE_ENV === 'development') {
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

ReactDOM.render(
  <LocalizationProvider locales={locales}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </LocalizationProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
