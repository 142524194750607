import React, { Fragment, useEffect, useRef, useState } from 'react';

import { GlobalStyles } from '../../../pages/questionnaire/Questionnaire';
import { H3, Header, PrimaryAction } from '../QuestionnaireStartPage';
import CheckboxWrapper from '../../../components/Questionnaire/CheckboxWrapper';
import CheckboxInput from '../../../components/Questionnaire/inputs/CheckboxInput';
import Checkmark from '../../../components/Questionnaire/Checkmark';
import Spacer from '../../../components/Spacer';
import SignatureCanvas from '../../../components/SignatureCanvas';
import {
  CheckboxLabel,
  Disclaimer,
  Document,
  DrawSignatureDisclaimer,
  Paragraph,
  Preview,
} from './DocumentSignatureStyle';

import { useMutation, useQuery } from 'react-apollo-hooks';
import { forms as formsQuery, signature as signatureQuery, signForms } from '../../../graphql/schema/form';
import FormPreview from './FormPreview';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { getFormType } from '../../../components/Questionnaire/utils/util';
import config from '../../../config';
import { useValueFromParent } from '../../../utils/useValueFromParent';

const MAX_TEXT_LENGTH = 100;

export const readTitle = title => (title.length < MAX_TEXT_LENGTH ? title : `${title.slice(0, MAX_TEXT_LENGTH)}...`);

const Checkbox = props => (
  <CheckboxWrapper>
    <CheckboxInput {...props} />
    <Checkmark />
  </CheckboxWrapper>
);

const DocumentItem = ({ id, name, handleIsChecked, isChecked, isSigned, readOnly, onPreviewClick }) => {
  const intl = useIntl();

  return (
    <Document>
      <Preview>
        <Disclaimer>
          <H3 lead>{readTitle(intl.formatMessage({ id: name, defaultMessage: name }))}</H3>
        </Disclaimer>
        <Button variant="contained" color="primary" onClick={onPreviewClick}>
          {intl.formatMessage({ id: 'questionnaire.signature.preview', defaultMessage: 'Preview' })}
        </Button>
      </Preview>
      <CheckboxLabel>
        <Checkbox
          id={id}
          type="checkbox"
          checked={isChecked}
          onChange={handleIsChecked}
          disabled={readOnly || isSigned}
        />
        <span style={{ fontSize: '1.3em' }}>
          <FormattedMessage
            id="questionnaire.signature.agreement"
            defaultMessage="I agree to use electronic records and signatures"
          />
        </span>
      </CheckboxLabel>
    </Document>
  );
};

const DocumentSignature = ({
  type,
  procedureId,
  onPreview,
  goBack,
  finish,
  formsForSigning,
  setFormsForSigning,
  loading,
}) => {
  const intl = useIntl();

  const sign = useMutation(signForms);
  const sigCanvas = useRef({});

  const {
    data: { signature: existingSignature },
  } = useQuery(signatureQuery, { variables: { procedureId, formType: getFormType(type) } });

  const [useOldSignature, setUseOldSignature] = useState(false);

  const [isCanvasEmpty, setIsCanvasEmpty] = useState(false);

  const clear = () => {
    sigCanvas.current.clear();
    setUseOldSignature(false);
    setIsCanvasEmpty(true);
  };

  const save = async () => {
    // dont use internal getTrimmedCanvas as it redraws canvas as a raster image
    // base64 svg will be a lot larger than compressed svg xml, but leave it for now

    const signature = useOldSignature ? existingSignature : sigCanvas.current.toDataURL('image/svg+xml');
    await sign({
      variables: { signature, formIds: formsForSigning.filter(_ => _.isChecked).map(_ => _.id) },
      refetchQueries: [
        { query: signatureQuery, variables: { procedureId, formType: getFormType(type) } },
        { query: formsQuery, variables: { procedureId, formType: getFormType(type) } },
      ],
    });
    finish();
  };

  useEffect(() => {
    if (sigCanvas && sigCanvas.current) {
      const pad = sigCanvas.current.getSignaturePad();
      pad.onEnd = () => setIsCanvasEmpty(sigCanvas.current.isEmpty());
    }

    if (sigCanvas && sigCanvas.current && existingSignature && sigCanvas.current.isEmpty()) {
      sigCanvas.current.fromDataURL(existingSignature);
      setUseOldSignature(true);
    }
  }, [sigCanvas, existingSignature]);

  const handleIsChecked = id => _ => {
    const data = [...formsForSigning];
    const item = data.find(item => item.id === id);
    item.isChecked = !item.isChecked;
    setFormsForSigning(data);
  };

  const waitingRoomTablet = useIsWaitingRoomTablet();

  // only users should be able to sign, presence of procedureId indicates super user
  const readOnly = procedureId && !waitingRoomTablet;
  const canSign =
    !loading &&
    !!formsForSigning.find(_ => _.isChecked) &&
    !readOnly &&
    !isCanvasEmpty &&
    (formsForSigning.filter(_ => _.isSigned).length < formsForSigning.length || !useOldSignature);

  const canSkip =
    formsForSigning.filter(_ => _.isSigned).length === formsForSigning.length && !isCanvasEmpty && useOldSignature;

  return (
    <div className="Page Disclaimer EvenPadding" style={{ justifyContent: 'flex-start' }}>
      <Header>
        <Typography textAlign="center" variant="h6">
          <FormattedMessage id="questionnaire.signature.title" defaultMessage="Document Electronic Signature" />
        </Typography>
      </Header>
      <Typography>
        <FormattedMessage id="questionnaire.signature.request" defaultMessage="Please read the Electronic documents" />
      </Typography>
      <Spacer size={1} />
      {formsForSigning.map(row => (
        <DocumentItem
          key={row.id}
          {...row}
          handleIsChecked={handleIsChecked(row.id)}
          readOnly={readOnly}
          onPreviewClick={() => onPreview(row.id)}
        />
      ))}
      <Spacer size={2} />
      <DrawSignatureDisclaimer>
        <Paragraph lead>
          <FormattedMessage id="questionnaire.signature.instruction" defaultMessage="Draw your signature here" />
        </Paragraph>
        <PrimaryAction>
          <Button variant="contained" size="small" onClick={clear} disabled={readOnly}>
            {intl.formatMessage({ id: 'questionnaire.signature.clear', defaultMessage: 'X Clear' })}
          </Button>
        </PrimaryAction>
      </DrawSignatureDisclaimer>
      <SignatureCanvas sigCanvas={sigCanvas} disabled={readOnly || useOldSignature} />
      <Spacer size={1} />
      <Grid container spacing={2}>
        <Grid item xs>
          <Button onClick={goBack} fullWidth>
            <FormattedMessage id="questionnaire.signature.back" defaultMessage="Back" />
          </Button>
        </Grid>
        <Grid item xs>
          {canSkip ? (
            <Button color="primary" onClick={finish} fullWidth>
              {intl.formatMessage({ id: 'questionnaire.signature.finish', defaultMessage: 'Finish' })}
            </Button>
          ) : (
            <Button color="primary" disabled={!canSign} onClick={save} fullWidth>
              {intl.formatMessage({ id: 'questionnaire.signature.save', defaultMessage: 'Save & Finish' })}
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const FormSignatureRouter = ({ type, onBack, onDone, procedureId, forms, setForms, loading }) => {
  const [previewId, setPreviewId] = useState();

  const previewClickHandler = id => {
    setPreviewId(id);
  };

  const onBackHandler = _ => {
    setPreviewId(undefined);
  };

  return (
    <Fragment>
      <GlobalStyles />
      {previewId ? (
        <FormPreview id={previewId} onBack={onBackHandler} procedureId={procedureId} />
      ) : (
        <DocumentSignature
          type={type}
          onPreview={previewClickHandler}
          goBack={onBack}
          finish={onDone}
          formsForSigning={forms}
          setFormsForSigning={setForms}
          procedureId={procedureId}
          loading={loading}
        />
      )}
    </Fragment>
  );
};

function useIsWaitingRoomTablet() {
  return useValueFromParent('IsWaitingRoomTablet');
}

export default FormSignatureRouter;
