import React from 'react';
import styled from 'styled-components';

import SmallLabelThemeSupport from './SmallLabelThemeSupport';

const Textarea = styled.textarea`
  background: none;
  border: 1px solid ${({ theme }) => theme.textBoxBorderColor};
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.textColor};
  font-family: Rubik, -apple-system, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  padding: 1em;
  box-sizing: border-box;
  font-size: 1.4em;
  border-color: 'rgba(255, 255, 255, .25)';
`;

const TextBox = ({ name, label, value, onChange, disabled, placeholder }) => (
  <React.Fragment>
    <SmallLabelThemeSupport message={label} />
    <Textarea placeholder={placeholder} name={name} rows="6" value={value} onChange={onChange} disabled={disabled} />
  </React.Fragment>
);

export default TextBox;
