import React, { Fragment } from 'react';
import PatientIcon from './PatientIcon';
import { useIntl } from 'react-intl';
import { Box, makeStyles } from '@material-ui/core';
import LocalePicker from '../../components/LocalePicker';
import Typography from '@material-ui/core/Typography';
import { Patient } from '../../types/patient';

const useStyles = makeStyles(theme => ({
  patient: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
}));

interface HeaderProps {
  patient?: Patient;
  isCaretaker?: boolean;
}

const Header = ({ patient = {}, isCaretaker = false }: HeaderProps) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Fragment>
      <Box mb={2}>
        <LocalePicker compact isCaretaker={isCaretaker} />
      </Box>

      <Box>
        <Typography color="textSecondary">
          {intl.formatMessage({ id: 'status.patient', defaultMessage: 'Patient' })}
        </Typography>
        <div className={classes.patient}>
          <Box mr={1}>
            <PatientIcon icon={patient.icon} backgroundColor={patient.color} inline={true} />
          </Box>
          <Typography variant="h3" component="span">
            {patient.name || ''}
          </Typography>
        </div>
      </Box>

      <Box>
        <Typography color="textSecondary">
          {intl.formatMessage({ id: 'status.physician', defaultMessage: 'Physician' })}
        </Typography>
        <Typography variant="h3">{patient.physician || ''}</Typography>
      </Box>
    </Fragment>
  );
};

export default Header;
