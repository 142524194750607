import React from 'react';
import SmallLabelThemeSupport from '../../components/SmallLabelThemeSupport';
import { FormattedMessage, useIntl } from 'react-intl';

const FeedbackSuccessState = () => {
  const intl = useIntl();

  return (
    <div className="Page PageCenter" style={{ textAlign: 'center' }}>
      <h1 style={{ color: '#FAFAFA' }}>
        <FormattedMessage id="feedback.success.title" defaultMessage="Thank you!" />
      </h1>
      <SmallLabelThemeSupport
        message={intl.formatMessage({ id: 'feedback.success.text', defaultMessage: 'Your suggestion has been sent.' })}
      />
    </div>
  );
};

export default FeedbackSuccessState;
