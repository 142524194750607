export const lightTheme = {
  body: '#F7F6F6',
  text: '#42484d',
  toggleBorder: '#FFF',
  background: 'linear-gradient(#39598A, #79D7ED)',
  hospitalInformationBackgroundColor: '#fff',
  textColor: '#3380a7',
  nodeColor: '#3380a7',
  activeNodeColor: '#3380a7',
  smallLabelColor: '#42484d',
  mediumLabelColor: '#42484d',
  starColor: '#ffa500',
  textBoxBorderColor: '#827771',
  feedbackSeparatorColor: '#42484d',
  externalFeedbackButtonColor: '#42484d',
  rateButtonColor: '#00a7f7',
  rateButtonHoverColor: '#003569',
  smallButtonColor: '#808080',
  borderSeparatorColor: '#808080',
};

export const darkTheme = {
  body: '#000C3F',
  text: '#FAFAFA',
  toggleBorder: '#6B8096',
  background: '#000C3F',
  hospitalInformationBackgroundColor: '#1a1e4c',
  textColor: '#fff',
  nodeColor: '#003569',
  activeNodeColor: '#00a7f7',
  smallLabelColor: '#e6ecef',
  mediumLabelColor: '#e6ecef',
  starColor: 'rgba(255, 255, 255, 0.25)',
  textBoxBorderColor: '#172351',
  feedbackSeparatorColor: '#ffffffb3',
  externalFeedbackButtonColor: '#ffffffb3',
  rateButtonColor: '#00a7f7',
  rateButtonHoverColor: '#003569',
  smallButtonColor: '#435182',
  borderSeparatorColor: '#ffffff3d',
};
