import React from 'react';
import { Route, Switch, withRouter } from 'react-router';
import { Query } from 'react-apollo';
import PatientStatus from './pages/PatientStatus';
import FeedbackForm from './pages/FeedbackForm';
import Homepage from './pages/Homepage';
import Manage from './pages/Manage';
import QuestionnaireApp from './pages/questionnaire/QuestionnaireApp';
import ConnectionProvider from './graphql/ConnectionProvider';
import Unauthorized from './pages/Unauthorized';
import Confirmation from './pages/Confirmation';
import SurgeryInstructions from './pages/SurgeryInstructions';
import { ThemeProvider } from '@material-ui/core/styles';
import { muiThemeLight } from './components/theme/muiTheme';
import RequestSignature from './pages/RequestSignature';
import PatientPickup from './pages/PatientPickup';
import { CssBaseline } from '@material-ui/core';
import ApproveAssignmentPage from './pages/ApproveAssignmentPage';
import { hospital } from './graphql/schema/familyMember';
import { arLocale, enLocale, locales } from './components/LocaleProvider';
import isMiddleEast from './utils/isMiddleEast';
import LocalizationProvider from './components/LocalizationProvider';

const App = ({ history }) => (
  <main>
    <ThemeProvider theme={muiThemeLight}>
      <CssBaseline />
      <Switch>
        <Route path="/manage" component={Manage} />
        <Route
          path="/*/:token"
          render={() => (
            <ConnectionProvider onUnauthorized={() => history.replace('/')}>
              <Query query={hospital}>
                {({ data: { patientHospital: hospital } }) => (
                  <LocalizationProvider locales={isMiddleEast(hospital?.id) ? [arLocale, enLocale] : locales}>
                    <Switch>
                      <Route path="/status/:token" component={PatientStatus} />
                      <Route path="/feedback/:token" component={FeedbackForm} />
                      <Route path="/instructions/:token" component={SurgeryInstructions} />
                      <Route
                        path="/questionnaire/:token"
                        render={props =>
                          isMiddleEast(hospital?.id) ? (
                            <LocalizationProvider locales={[enLocale]}>
                              <QuestionnaireApp {...props} />
                            </LocalizationProvider>
                          ) : (
                            <QuestionnaireApp {...props} />
                          )
                        }
                      />
                      <Route path="/confirm/:token" component={Confirmation} />
                      <Route path="/request-signature/:token" component={RequestSignature} />
                      <Route path="/patient-pickup/:token" component={PatientPickup} />
                      <Route path="/open-position/:token" component={ApproveAssignmentPage} />
                      <Route component={Unauthorized} />
                    </Switch>
                  </LocalizationProvider>
                )}
              </Query>
            </ConnectionProvider>
          )}
        />
        <Route exact path="/" component={Homepage} />
        <Route component={Unauthorized} />
      </Switch>
    </ThemeProvider>
  </main>
);

export default withRouter(App);
