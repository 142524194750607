import React, { useEffect } from 'react';
import PatientStatus from '../../../enums/PatientStatus';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import { Box, makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import isMiddleEast from '../../../utils/isMiddleEast';

const useStyles = makeStyles(theme => ({
  stepLabel: {
    fontSize: theme.typography.h5.fontSize,
  },
}));

interface PatientProgressTrackerProps {
  ADMITTED: boolean;
  PRE_OP: boolean;
  IN_OR: boolean;
  PACU: boolean;
  POST_OP: boolean;
  ABLE_TO_VISIT_PACU: boolean;
  ABLE_TO_VISIT_POSTOP: boolean;
  current: PatientStatus;
  omitAbleToVisit: boolean;
  showPostOp: boolean;
  hospital: any;
}

const PatientProgressTracker = ({
  ADMITTED,
  PRE_OP,
  IN_OR,
  PACU,
  POST_OP,
  current,
  showPostOp,
  hospital,
}: PatientProgressTrackerProps) => {
  const intl = useIntl();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState<number>(0);

  const steps = [
    {
      label: intl.formatMessage({ id: 'status.admitted', defaultMessage: 'Admitted' }),
      status: ADMITTED,
    },
    {
      label: intl.formatMessage({ id: 'status.preparing-for-surgery', defaultMessage: 'Preparing for Surgery' }),
      status: PRE_OP,
    },
    {
      label: intl.formatMessage({ id: 'status.in-surgery', defaultMessage: 'In Surgery' }),
      status: IN_OR,
    },
    {
      label: intl.formatMessage({ id: 'status.in-recovery', defaultMessage: 'In Recovery' }),
      status: PACU,
      ableToVisit: current === PatientStatus.ABLE_TO_VISIT_PACU,
    },
    {
      label: isMiddleEast(hospital?.id)
        ? intl.formatMessage({ id: 'status.in-icu', defaultMessage: 'In ICU' })
        : intl.formatMessage({ id: 'status.in-postop', defaultMessage: 'In Post Op' }),
      status: POST_OP,
      omit: !showPostOp,
      ableToVisit: current === PatientStatus.ABLE_TO_VISIT_POSTOP,
    },
  ];

  function getStepContent(current: PatientStatus): number {
    switch (current) {
      case PatientStatus.ADMITTED:
        return 0;
      case PatientStatus.PRE_OP:
        return 1;
      case PatientStatus.IN_OR:
        return 2;
      case PatientStatus.PACU:
        return 3;
      case PatientStatus.IN_POST_OP:
        return 4;
      default:
        return -1;
    }
  }

  useEffect(() => {
    setActiveStep(getStepContent(current));
  }, [current]);

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map(
        (step, i) =>
          !step.omit && (
            <Step key={i} expanded={Boolean(step.status)} completed={Boolean(step.status)}>
              <StepLabel classes={{ label: classes.stepLabel }}>
                <Box style={{ display: 'flex' }}>
                  {step.label}
                  {step.ableToVisit && (
                    <div
                      style={{
                        width: '80px',
                        height: '25px',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#003c0a',
                        background: '#43be57',
                        borderRadius: '0.1875rem',
                        fontSize: '2.5vw',
                        padding: '8px 0px 0.5em 0.5em',
                        marginLeft: '0.6em',
                        textAlign: 'center',
                      }}
                    >
                      {intl.formatMessage({ id: 'status.able-to-visit', defaultMessage: 'Able to visit' })}
                    </div>
                  )}
                </Box>
              </StepLabel>
              <StepContent>
                <Typography color="textSecondary" variant="body2">
                  {step.status}
                </Typography>
              </StepContent>
            </Step>
          )
      )}
    </Stepper>
  );
};

export default PatientProgressTracker;
