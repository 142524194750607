import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export const CircularProgressWithLabel = ({ value, label }) => (
  <Box position="relative" display="inline-flex">
    <CircularProgress variant="determinate" value={value} aria-label={label} />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="caption" component="div" color="textSecondary">
        {label}
      </Typography>
    </Box>
  </Box>
);
