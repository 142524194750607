import React from 'react';
import { graphql } from 'react-apollo';
import { ThemeProvider } from '@material-ui/core/styles';
import HospitalInformation from '../sections/PatientStatus/HospitalInformation';
import { hospital as hospitalQuery, patientSubscription } from '../graphql/schema/familyMember';
import ActivePatient from '../sections/PatientStatus/ActivePatient';
import DischargedPatient from '../sections/PatientStatus/DischargedPatient';
import { useQuery } from 'react-apollo-hooks';
import { Container, CssBaseline, makeStyles } from '@material-ui/core';
import { muiThemeDark } from '../components/theme/muiTheme';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100%',
  },
});

const PatientStatus = ({ data = {} }) => {
  const classes = useStyles();
  const { familyMember: patient } = data;
  const { data: hospitalData, loading: hospitalLoading } = useQuery(hospitalQuery);
  const hospital = (hospitalData || {}).patientHospital || {};

  if (patient === undefined || hospitalLoading) {
    return null;
  }

  if (patient === null || (patient && patient.events.dischargedAt)) {
    return (
      <ThemeProvider theme={muiThemeDark}>
        <CssBaseline />
        <Container maxWidth="sm" className={classes.root}>
          <DischargedPatient />
          <Box mt={2}>
            <HospitalInformation />
          </Box>
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={muiThemeDark}>
      <CssBaseline />
      <Container maxWidth="sm">
        <Box pt={3}>
          <ActivePatient patient={patient} hospital={hospital} />
          <Box mt={2}>
            <HospitalInformation />
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default graphql(patientSubscription)(PatientStatus);
