import React, { useState } from 'react';
import FormContext from '../FormContext';
import { QuestionnaireContext } from '../../../pages/questionnaire/QuestionnaireApp';
import { useQuery } from 'react-apollo-hooks';
import { searchMedications } from '../../../graphql/schema/medication';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress, FormHelperText, TextField } from '@material-ui/core';
import InputWrapper from '../InputWrapper';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useIntl } from 'react-intl';
import TranslatedMessage from '../TranslatedMessage';
import { FieldType, validatorFn, ValidatorType } from '../utils/validators/validateRequired';
import ValidationWrapper from './ValidationWrapper';

const removeDuplicates = values => {
  if (!values) return null;
  const valueNameArr = values.map(e => `${e.drugName} ${e.activeIngredient}`);
  const uniqueValueNames = [...new Map(valueNameArr.map(str => [str.replace(/\s+/g, ''), str])).values()];
  return values.filter(e => uniqueValueNames.includes(`${e.drugName} ${e.activeIngredient}`));
};

const getOptionLabel = option =>
  option.drugName && option.activeIngredient && option.drugName === option.activeIngredient
    ? option.drugName
    : option.drugName && option.activeIngredient
    ? `${option.drugName} ${option.activeIngredient}`
    : null;

const AutoComplete = ({ defaultValue, submitValue, explanation, error, required }) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(defaultValue ?? '');

  const { data, loading } = useQuery(searchMedications, {
    variables: { search: inputValue },
  });
  const options = removeDuplicates(data?.searchMedications) || [];
  const sortedOptions = [
    ...options.map(getOptionLabel).filter(v => v.startsWith(inputValue.toUpperCase())),
    ...options.map(getOptionLabel).filter(v => !v.startsWith(inputValue.toUpperCase())),
  ];

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={defaultValue}
      onChange={(event, newValue) => {
        submitValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        submitValue(newInputValue);
      }}
      options={sortedOptions}
      loading={loading}
      freeSolo={true}
      fullWidth={true}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={intl.formatMessage({ id: 'questionnaire.medication.label', defaultMessage: 'Name' })}
          placeholder={intl.formatMessage({
            id: 'questionnaire.medication.placeholder',
            defaultMessage: 'Start typing to see suggestions',
          })}
          required={required}
          error={!!error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          helperText={
            <>
              {!!error && (
                <FormHelperText style={{ color: 'red' }}>
                  <TranslatedMessage message={error} />
                </FormHelperText>
              )}
              {explanation && <TranslatedMessage message={explanation} />}
            </>
          }
        />
      )}
      renderOption={(option, { inputValue }) => {
        const matches = match(option, inputValue);
        const parts = parse(option, matches);

        return (
          <div>
            {parts.map((part, index) => (
              <span key={index} style={{ fontWeight: part.highlight ? 500 : 400 }}>
                {part.text}
              </span>
            ))}
          </div>
        );
      }}
    />
  );
};

const TextInput = ({ defaultValue, submitValue, explanation, required, error }) => {
  const intl = useIntl();

  return (
    <TextField
      type="text"
      variant="outlined"
      label={intl.formatMessage({ id: 'questionnaire.medication.label', defaultMessage: 'Name' })}
      value={defaultValue}
      onChange={e => submitValue(e.target.value)}
      autoComplete="off"
      required={required}
      error={!!error}
      helperText={
        <>
          {!!error && (
            <FormHelperText style={{ color: 'red' }}>
              <TranslatedMessage message={error} />
            </FormHelperText>
          )}
          {explanation && <TranslatedMessage message={explanation} />}
        </>
      }
    />
  );
};

const MedicationInput = ({ label, limit, length, optional, section, explanation, validators }) => {
  const [error, setError] = useState(null);
  const rules = validators ? validators.map(type => validatorFn[type]?.(FieldType.Text) ?? (() => '')) : [];
  const required = !!validators?.find(e => e === ValidatorType.isRequired);
  return (
    <QuestionnaireContext.Consumer>
      {({ isAdmin }) => (
        <FormContext.Consumer>
          {({ name, value, onChange }) => {
            const defaultValue = value?.text || '';
            const submitValue = newValue => {
              onChange({ text: newValue, progress: value?.progress || 0 });
            };

            return (
              <ValidationWrapper value={defaultValue} rules={rules} error={error} setError={setError}>
                <InputWrapper>
                  {isAdmin ? (
                    <AutoComplete
                      name={name}
                      defaultValue={defaultValue}
                      submitValue={submitValue}
                      explanation={explanation}
                      required={required}
                      error={error}
                    />
                  ) : (
                    <TextInput
                      name={name}
                      defaultValue={defaultValue}
                      submitValue={submitValue}
                      explanation={explanation}
                      required={required}
                      error={error}
                    />
                  )}
                </InputWrapper>
              </ValidationWrapper>
            );
          }}
        </FormContext.Consumer>
      )}
    </QuestionnaireContext.Consumer>
  );
};

MedicationInput.defaultValue = { text: '', progress: 0 };

export default MedicationInput;
