import gql from 'graphql-tag';

export const reviewIntegrationFragments = {
  base: `
    fragment ReviewBase on ReviewIntegration {
      link
      enabled
    }
  `,
};

export const reviewIntegrationsFragments = {
  all: `
    fragment ReviewIntegrationsData on ReviewIntegrations {
      google {
        ...ReviewBase
      }
      yelp {
        ...ReviewBase
      }
    }
    ${reviewIntegrationFragments.base}
  `,
};

export const myFeedback = gql`
  query myFeedback {
    myFeedback {
      id
      rating
      suggestion
      submittedAt
    }

    patientHospital {
      id
      reviewIntegrations {
        ...ReviewIntegrationsData
      }
      caretakerReviewIntegrations {
        ...ReviewIntegrationsData
      }
      name
    }

    myPhysician {
      id
      name
      reviewIntegrations {
        ...ReviewIntegrationsData
      }
    }

    isCaretaker
  }
  ${reviewIntegrationsFragments.all}
`;

export const myPhysician = gql`
  query myPhysician {
    myPhysician {
      id
      name
      reviewIntegrations {
        ...ReviewIntegrationsData
      }
    }
  }
  ${reviewIntegrationsFragments.all}
`;

export const submitFeedback = gql`
  mutation submitFeedback($rating: Int!, $suggestion: String) {
    submitFeedback(rating: $rating, suggestion: $suggestion) {
      id
      rating
      suggestion
      submittedAt
    }
  }
`;

const rating = {
  myFeedback,
  submitFeedback,
};

export default rating;
