import React from 'react';
import { Query } from 'react-apollo';
import { procedure } from '../../graphql/schema/questionnaire';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';

const PatientName = ({ procedureId }) =>
  procedureId ? (
    <Query query={procedure} variables={{ id: procedureId }} skip={!procedureId}>
      {({ data }) => (
        <Typography variant="h5" color="textSecondary" gutterBottom>
          {get(data, 'procedure.patientName')}
        </Typography>
      )}
    </Query>
  ) : null;

export default PatientName;
