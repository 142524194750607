import { Messages } from '../types/localization';

const ch: Messages = {
  'questionnaire.call': '电话',
  'questionnaire.directions': '方向',
  'questionnaire.welcome.title': '欢迎来到在线患者登记',
  'questionnaire.welcome.lead.hospital':
    '{hospitalName}需要您的健康信息和医疗历史，以便为您提供最佳的护理。请填写这份在线调查问卷。简单、安全和保密。',
  'questionnaire.welcome.lead.generic':
    '我们需要您的健康信息和医疗历史，以便为您提供最佳的护理。请填写这份在线调查问卷。简单、安全和保密。',
  'questionnaire.welcome.instructions.request': '请确保您准备好以下信息：',
  'questionnaire.welcome.instructions.prescription': '处方药的名称和剂量',
  'questionnaire.welcome.instructions.over-the-counter': '您服用的非处方药的名称和剂量',
  'questionnaire.welcome.instructions.allergies': '您可能患有的过敏和其反应',
  'questionnaire.welcome.instructions.surgeries': '您之前接受的手术和年份',
  'questionnaire.welcome.call': '在收到您的信息后，您可能会接到医务人员的电话，以澄清某些问题。',
  'questionnaire.welcome.end': '我们期待为您提供最佳的医疗护理和服务',
  'questionnaire.welcome.continue': '继续',
  'questionnaire.tabs.questionnaire': '调查问卷',
  'questionnaire.tabs.docs': '上传',
  'questionnaire.tabs.signature': '签名',
  'questionnaire.error.submission': '提交表单时出错。请重试。如果问题仍然存在，请联系客服人员。',
  'questionnaire.sections.annex.confirmation.title': '确认操作',
  'questionnaire.sections.annex.confirmation.text':
    '您已经签署了预先医疗表格。更改问卷将导致需要丢弃这些表格。您是否要编辑？',
  'questionnaire.sections.back': '返回',
  'questionnaire.sections.save.finish': '保存并完成',
  'questionnaire.sections.save.continue': '保存并继续',
  'questionnaire.list.add': '添加',
  'questionnaire.list.remove': '删除',
  'questionnaire.text.limit.used': '{remaining, plural, =1 {剩余一个字符} other {剩余 # 个字符}}',
  'questionnaire.text.limit.unused': '最大长度：{limit, plural, =1 {一个字符} other {# 个字符}}',
  'questionnaire.dob.title': '出生日期',
  'questionnaire.dob.placeholder.day': '日',
  'questionnaire.dob.placeholder.month': '月',
  'questionnaire.dob.placeholder.year': '年',
  'questionnaire.dob.day': '日',
  'questionnaire.dob.month': '月',
  'questionnaire.dob.year': '年',
  'questionnaire.date.placeholder.day': '日',
  'questionnaire.date.placeholder.month': '月',
  'questionnaire.date.placeholder.year': '年',
  'questionnaire.date.day': '日',
  'questionnaire.date.month': '月',
  'questionnaire.date.year': '年',
  'questionnaire.bmi.height': '身高',
  'questionnaire.bmi.feet': '英尺',
  'questionnaire.bmi.inches': '英寸',
  'questionnaire.bmi.weight': '体重',
  'questionnaire.bmi.pounds': '磅',
  'questionnaire.csz.city': '城市',
  'questionnaire.csz.state': '州',
  'questionnaire.csz.zip': '邮政编码',
  'questionnaire.yesno.yes': '是',
  'questionnaire.yesno.no': '否',
  'questionnaire.oneof.choose': '选择一个选项',
  'questionnaire.medication.label': '名称',
  'questionnaire.medication.placeholder': '开始输入以查看建议',
  'questionnaire.checkboxes.question': '选择所有适用的选项',
  'questionnaire.checkboxes.none': '无',
  'questionnaire.success.call': '电话',
  'questionnaire.success.directions': '地址',
  'questionnaire.success.thanks': '谢谢！',
  'questionnaire.success.docs.uploaded': '已上传文件。',
  'questionnaire.success.signature.submitted': '签名已提交。',
  'questionnaire.success.questionnaire.submitted': '成功提交问卷 {progress}%',
  'questionnaire.success.staff.call': '问卷已成功提交。工作人员或护士可能会致电您以获取更多信息。',
  'questionnaire.success.back': '返回表格',
  'questionnaire.success.close': '关闭',
  'questionnaire.docs.title': '上传文件',
  'questionnaire.docs.empty': '这里没有文件。',
  'questionnaire.docs.delete': '删除',
  'questionnaire.docs.confirm': '确认操作',
  'questionnaire.docs.sure': '您确定要删除此文件吗？',
  'questionnaire.docs.finish': '完成',
  'questionnaire.signature.title': '电子签名',
  'questionnaire.signature.request': '请阅读电子文档',
  'questionnaire.signature.instruction': '在此处签名',
  'questionnaire.signature.preview': '预览',
  'questionnaire.signature.agreement': '我同意使用电子记录和签名',
  'questionnaire.signature.clear': '清除数据',
  'questionnaire.signature.back': '返回',
  'questionnaire.signature.finish': '完成',
  'questionnaire.signature.save': '保存并完成',
  'homepage.instructions': '请输入您的PIN码',
  'homepage.error.pin': 'PIN码无效，请重试。',
  'homepage.error.unexpected': '发生意外错误，请稍后重试。',
  'homepage.helper': '您可以在手术中心发来的短信中找到PIN码。',
  'status.patient': '患者',
  'status.physician': '医生',
  'status.status': '状态',
  'status.time': '时间',
  'status.admitted': '已入院',
  'status.preparing-for-surgery': '准备手术',
  'status.in-surgery': '手术中',
  'status.in-recovery': '恢复中',
  'status.in-postop': '术后',
  'status.able-to-visit': '可以探访',
  'status.final.title': '此患者的访问已结束',
  'status.final.subtitle': '此患者的访问已结束',

  'hospital.call': '请拨打电话',
  'hospital.directions': '获取路线',
  'feedback.error': '发送表单时出现错误，请重试。如果问题仍然存在，请联系客服人员。',
  'feedback.share.question': '您是否愿意与他人分享您的经验？',
  'feedback.share.google': '在Google上留下评论',
  'feedback.share.yelp': '在Yelp上给我们评分',
  'feedback.share.skip': '跳过',
  'feedback.suggestion.question': '您是否想要留下评论或告诉我们如何改善您的体验？',
  'feedback.send': '发送反馈',
  'feedback.success.title': '非常感谢！',
  'feedback.success.text': '您的建议已发送。',
  'rating.instructions': '请对您的体验进行评分',
  'rating.thanks': '非常感谢！',
  'This field is required': '此字段为必填项',
  'Please fill in the required fields': '请填写必填字段',
};

export default ch;
