import gql from 'graphql-tag';

export const caretakerConfirmation = gql`
  mutation saveCaretakerPickupMessage($content: String) {
    saveCaretakerPickupMessage(content: $content)
  }
`;

export const getNotificationTemplate = gql`
  query getNotificationTemplate(
    $trigger: NotificationTemplateTrigger!
    $channel: NotificationTemplateChannel
    $destination: NotificationTemplateDestination
  ) {
    getNotificationTemplate(trigger: $trigger, channel: $channel, destination: $destination) {
      id
      name
      content
      description
      trigger
      destination
      subject
      channel
      enabled
      additionalInfo
    }
  }
`;
