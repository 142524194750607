import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Document, Page, pdfjs } from 'react-pdf';
import styled, { css } from 'styled-components';
import Icon from '@material-ui/core/Icon';
import { Link, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ToolbarItem = styled.div`
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  z-index: 5;
  transition: all 0.4s ease;

  opacity: 0.6;

  :hover {
    opacity: 1;
  }

  ${props =>
    !props.visible &&
    css`
      opacity: 0;
    `}
`;

const PageStepperContainer = styled(ToolbarItem)`
  display: flex;
  align-items: baseline;
`;

const Action = styled(ToolbarItem)`
  display: flex;
`;

const themeDisabled = createMuiTheme({
  palette: {
    action: {
      disabled: 'white',
    },
  },
});

const Pager = ({ numPages, pageNumber, prevPage, nextPage, visible }) => (
  <PageStepperContainer visible={visible} style={{ color: 'white' }}>
    <ThemeProvider theme={pageNumber <= 1 ? themeDisabled : undefined}>
      <Button onClick={prevPage} disabled={pageNumber <= 1} color={'primary'}>
        ‹
      </Button>
    </ThemeProvider>
    <p style={{ overflow: 'hidden', whiteSpace: 'nowrap', margin: '0.5rem' }}>
      {pageNumber} / {numPages}
    </p>
    <ThemeProvider theme={pageNumber >= numPages ? themeDisabled : undefined}>
      <Button onClick={nextPage} disabled={pageNumber >= numPages} color={'primary'}>
        ›
      </Button>
    </ThemeProvider>
  </PageStepperContainer>
);

const Download = ({ url }) => (
  <Link
    href={url}
    style={{ color: 'white', fontSize: '1rem', padding: '6px 16px', display: 'flex', alignItems: 'center' }}
  >
    <Icon>arrow_downward</Icon>
  </Link>
);

const Print = ({ url }) => {
  const print = async () => {
    try {
      const data = await fetch(url);
      const blob = await data.blob();
      const blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <IconButton onClick={print} style={{ color: 'white', fontSize: '1rem', padding: '6px 16px' }}>
      <Icon>print</Icon>
    </IconButton>
  );
};

const usePdfViewerStyles = makeStyles(theme => ({
  root: {
    margin: '1em 0',
    border: `${theme.palette.type === 'dark' ? 'default' : `1px solid ${theme.palette.divider}`}`,
    display: 'flex',
    flexDirection: 'column',
  },
  controls: {
    display: 'flex',
    padding: '0.5rem',
    justifyContent: 'center',
    '& > *': {
      margin: '0 0.25rem',
    },
  },
}));

const PdfViewer = ({ url }) => {
  const classes = usePdfViewerStyles();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
  const prevPage = () => setPageNumber(pageNumber - 1);
  const nextPage = () => setPageNumber(pageNumber + 1);
  const visible = numPages !== null;

  const [width, setWidth] = useState(undefined);

  const rootRef = useRef();

  useEffect(() => {
    setWidth(rootRef.current.clientWidth);
  }, []);

  return (
    <div className={classes.root} ref={rootRef}>
      <div className={classes.controls}>
        <Pager numPages={numPages} pageNumber={pageNumber} prevPage={prevPage} nextPage={nextPage} visible={visible} />
        <Action visible={visible}>
          <Download url={url} />
        </Action>
        <Action visible={visible}>
          <Print url={url} />
        </Action>
      </div>
      {width && (
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} width={width} />
        </Document>
      )}
    </div>
  );
};

export default PdfViewer;
