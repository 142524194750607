import { Messages } from '../types/localization';

const es: Messages = {
  'questionnaire.call': 'Llamada',
  'questionnaire.directions': 'Direcciones',
  'questionnaire.welcome.title': 'Bienvenido al registro en línea de paciente',
  'questionnaire.welcome.lead.hospital':
    '{hospitalName} necesitan su información de salud e historial médico para poder ofrecerle el mejor cuidado posible. Por favor llene este cuestionario en línea. Es simpe, seguro y confidencial.',
  'questionnaire.welcome.lead.generic':
    'Necesitamos su información de salud e historial médico para poder ofrecerle el mejor cuidado posible. Por favor llene este cuestionario en línea. Es simpe, seguro y confidencial.',
  'questionnaire.welcome.instructions.request': 'Por favor asegúrese de tener la siguiente información a mano:',
  'questionnaire.welcome.instructions.prescription': 'Nombres y dosis de sus medicinas de prescripción',
  'questionnaire.welcome.instructions.over-the-counter': 'Nombres y dosis de las medicinas de venta libre que consume',
  'questionnaire.welcome.instructions.allergies': 'Alergias que pueda tener y sus reacciones',
  'questionnaire.welcome.instructions.surgeries': 'Cirugías previas y el año en el que las tuvo',
  'questionnaire.welcome.call':
    'Después de recibir esta información, puede que reciba la llamada de un miembro del personal o un enfermero o enfermera para aclarar algún punto.',
  'questionnaire.welcome.end': 'Esperamos proveerle el mejor cuidado y servicio médico posible',
  'questionnaire.welcome.continue': 'Continuar',
  'questionnaire.tabs.questionnaire': 'Cuestionario',
  'questionnaire.tabs.docs': 'Subir',
  'questionnaire.tabs.signature': 'Firma',
  'questionnaire.error.submission':
    'Se ha producido un error al enviar el formulario. Por favor, inténtalo de nuevo. Si el problema persiste, ponte en contacto con el personal del servicio de asistencia.',
  'questionnaire.sections.annex.confirmation.title': 'Confirma la acción',
  'questionnaire.sections.annex.confirmation.text':
    'Ya firmaste los formularios premédicos. Al cambiar el cuestionario, tendrás que desechar los formularios. ¿Quieres editar?',
  'questionnaire.sections.back': 'Atrás',
  'questionnaire.sections.save.finish': 'Guardar y finalizar',
  'questionnaire.sections.save.continue': 'Guardar y Continuar',
  'questionnaire.list.add': 'Agregar',
  'questionnaire.list.remove': 'Eliminar',
  'questionnaire.text.limit.used': `{remaining, plural,
        =1 {Queda un caractere}
        other {Quedan # caracteres}}`,
  'questionnaire.text.limit.unused': `Longitud máxima: {limit, plural,
        =1 {Una caractere}
        other {# caracteres}}`,
  'questionnaire.dob.title': 'Fecha de nacimiento',
  'questionnaire.dob.placeholder.day': 'DD',
  'questionnaire.dob.placeholder.month': 'MM',
  'questionnaire.dob.placeholder.year': 'AAAA',
  'questionnaire.dob.day': 'Día',
  'questionnaire.dob.month': 'Mes',
  'questionnaire.dob.year': 'Año',
  'questionnaire.date.placeholder.day': 'DD',
  'questionnaire.date.placeholder.month': 'MM',
  'questionnaire.date.placeholder.year': 'AAAA',
  'questionnaire.date.day': 'Día',
  'questionnaire.date.month': 'Mes',
  'questionnaire.date.year': 'Año',
  'questionnaire.bmi.height': 'Altura',
  'questionnaire.bmi.feet': 'Pies',
  'questionnaire.bmi.inches': 'Pulgadas',
  'questionnaire.bmi.weight': 'Peso',
  'questionnaire.bmi.pounds': 'Libras',
  'questionnaire.csz.city': 'Ciudad',
  'questionnaire.csz.state': 'Estado',
  'questionnaire.csz.zip': 'Código Postal',
  'questionnaire.yesno.yes': 'Sí',
  'questionnaire.yesno.no': 'No',
  'questionnaire.oneof.choose': 'Elija una opción',
  'questionnaire.medication.label': 'Nombre',
  'questionnaire.medication.placeholder': 'Empiece a escribir para ver sugerencias',
  'questionnaire.checkboxes.question': 'Marque todas las que apliquen',
  'questionnaire.checkboxes.none': 'Ninguna de las anteriores',
  'questionnaire.success.call': 'Llamada',
  'questionnaire.success.directions': 'Direcciones',
  'questionnaire.success.thanks': '¡Gracias!',
  'questionnaire.success.docs.uploaded': 'Documentos cargados.',
  'questionnaire.success.signature.submitted': 'Firma enviada.',
  'questionnaire.success.questionnaire.submitted': 'El cuestionario se envió con éxito {progress}%',
  'questionnaire.success.staff.call':
    'El cuestionario fue enviado con éxito. Un miembro del personal o una enfermera puede llamarlo para obtener más aclaraciones.',
  'questionnaire.success.back': 'Volver a la forma',
  'questionnaire.success.close': 'cerrar',
  'questionnaire.docs.title': 'Carga de Documentos',
  'questionnaire.docs.empty': 'No hay documentos aquí.',
  'questionnaire.docs.delete': 'Eliminar',
  'questionnaire.docs.confirm': 'Confirma la acción',
  'questionnaire.docs.sure': '¿Estás seguro de que deseas eliminar este archivo?',
  'questionnaire.docs.finish': 'Finalizar',
  'questionnaire.signature.title': 'Firma electrónica del documento',
  'questionnaire.signature.request': 'Por favor, lee los documentos electrónicos',
  'questionnaire.signature.instruction': 'Pon tu firma aquí',
  'questionnaire.signature.preview': 'Vista previa',
  'questionnaire.signature.agreement': 'Acepto usar registros y firmas electrónicas',
  'questionnaire.signature.clear': 'Eliminar los datos',
  'questionnaire.signature.back': 'Atrás',
  'questionnaire.signature.finish': 'Finalizar',
  'questionnaire.signature.save': 'Guardar Y Finalizar',
  'homepage.instructions': 'Por favor, introduce tu PIN',
  'homepage.error.pin': 'El PIN no es válido, inténtalo de nuevo.',
  'homepage.error.unexpected': 'Ocurrió un error inesperado, por favor, inténtalo de nuevo más tarde.',
  'homepage.helper': 'Puedes encontrar el PIN en el mensaje de texto que has recibido del centro quirúrgico.',
  'status.patient': 'Paciente',
  'status.physician': 'Médico',
  'status.status': 'Estado',
  'status.time': 'Hora',
  'status.admitted': 'Admitido',
  'status.preparing-for-surgery': 'Preparación para la cirugía',
  'status.in-surgery': 'En Cirugía',
  'status.in-recovery': 'En Recuperación',
  'status.in-postop': 'En Postoperatorio',
  'status.able-to-visit': 'Disponible para Recibir Visitas',
  'status.final.title': 'La visita de este paciente ha concluido.',
  'status.final.subtitle': 'La visita de este paciente ha concluido.',

  'hospital.call': 'Llame',
  'hospital.directions': 'Pida Direcciones',
  'feedback.error':
    'Se ha producido un error al enviar el formulario. Por favor, inténtalo de nuevo. Si el problema persiste, ponte en contacto con el personal del servicio de asistencia.',
  'feedback.share.question': '¿Quieres compartir tu experiencia con otros?',
  'feedback.share.google': 'Deja un comentario en Google',
  'feedback.share.yelp': '¡Califícanos en Yelp!',
  'feedback.share.skip': 'Saltar',
  'feedback.suggestion.question': '¿Le gustaría dejar un comentario o decirnos cómo podríamos mejorar su experiencia?',
  'feedback.send': 'Enviar comentarios',
  'feedback.success.title': '¡Muchas Gracias!',
  'feedback.success.text': 'Tu sugerencia ha sido enviada.',
  'rating.instructions': 'Califique su experiencia',
  'rating.thanks': '¡Muchas gracias!',
  'This field is required': 'Este campo es obligatorio',
  'Please fill in the required fields': 'Por favor, rellene los campos obligatorios',
};

export default es;
