import React from 'react';
import SignaturePad from 'react-signature-canvas';
import styled, { css } from 'styled-components';
import './SignatureCanvas.css';

const PadWrapper = styled.div`
  ${props =>
    props.disabled &&
    css`
      & > * {
        background: rgba(100, 100, 100, 0.15);
        pointer-events: none;
      }
    `}
`;

const SignatureCanvas = ({ sigCanvas, disabled }) => (
  <PadWrapper disabled={disabled}>
    <SignaturePad
      ref={sigCanvas}
      canvasProps={{
        className: 'signatureCanvas',
      }}
      clearOnResize={false}
    />
  </PadWrapper>
);

export default SignatureCanvas;
