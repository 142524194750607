import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  rating: {
    fontSize: '4em',
  },
});

const RatingWidget = ({ rating, onChange, done }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h1" align="center" gutterBottom>
        {!done ? (
          <FormattedMessage id="rating.instructions" defaultMessage="Rate Your Experience" />
        ) : (
          <FormattedMessage id="rating.thanks" defaultMessage="Thank you!" />
        )}
      </Typography>

      <Box display="flex" justifyContent="center">
        <Rating
          name="simple-controlled"
          value={rating}
          size="large"
          className={classes.rating}
          onChange={(event, newValue) => {
            onChange(newValue);
          }}
        />
      </Box>
    </div>
  );
};

export default RatingWidget;
