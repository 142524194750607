import styled from 'styled-components';

export const CheckboxLabel = styled.label`
  font-size: 0.8em;
  display: flex;
  align-items: center;
  margin-top: -20px;
  margin-bottom: 1.25em;
  margin-right: 5px;
  color: #000c3f;
  line-height: 1.45;
`;

export const Disclaimer = styled.div`
  text-align: left;
  flex: auto;
  min-height: 0;
  padding: 2px;

  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25em;
  margin-right: 1em;
  padding-left: 0.5em;
  display: flex;
  align-items: center;
  > h3 {
    margin-bottom: 0;
  }
`;

export const Preview = styled.div`
  display: flex;

  text-align: center;
  min-height: 0;
  justify-content: space-between;
  margin-bottom: 2em;
`;

export const Document = styled.div`
  margin: 0px -3px 10px -3px;
`;

export const DrawSignatureDisclaimer = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  min-height: 0;
`;

export const Paragraph = styled.p`
  line-height: 1.5;
  opacity: 0.65;
  font-size: 1.4em;

  @media (max-width: 500px) {
    font-size: 1em;
  }
`;
