import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import {
  confirmFormUpload,
  removeUploadedForm,
  uploadFormTypesForProcedurePreOp,
  uploadRequest,
} from '../../../graphql/schema/form';
import { useApolloClient } from 'react-apollo-hooks';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FilePicker } from 'react-file-picker';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Toolbar from '@material-ui/core/Toolbar';
import Image from 'material-ui-image';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonBase from '@material-ui/core/ButtonBase';
import CloseIcon from '@material-ui/icons/Close';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import './lightboxStyles.css';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    padding: theme.spacing(0, 2, 2),
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  panel: {
    display: 'flex',
    flexDirection: 'column',
  },
  appBar: {},
  title: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  pdfItem: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'space-evenly',
  },
  white: {
    color: 'white',
  },
  thumbLabel: {
    whiteSpace: 'break-spaces',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
    overflow: 'hidden',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CircularProgressWithLabel = props => (
  <Box
    position="absolute"
    display="inline-flex"
    zIndex="999"
    top="50%"
    left="50%"
    style={{ transform: 'translate(-50%, -50%)' }}
  >
    <CircularProgress variant="indeterminate" {...props} />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="caption" component="div" color="textSecondary" />
    </Box>
  </Box>
);
const Document = ({ title, icon, procedure, form, formType, uploads = [] }) => {
  const intl = useIntl();

  const theme = useTheme();
  const [images, setImages] = useState([]);
  const [preview, setPreview] = useState(null);
  const classes = useStyles();

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmLightboxOpen, setConfirmLightboxOpen] = useState(false);
  const [itemUploadings, setItemUploadings] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const isMd = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  const client = useApolloClient();

  const procedureId = procedure; // fixed
  const id = form; // fixed
  useEffect(() => {
    const items = uploads.map(url => {
      let extractedName = url.substring(url.lastIndexOf('/') + 1);
      extractedName = extractedName.substring(id.toString().length + 1, extractedName.indexOf('?'));
      return {
        type: url.indexOf('.pdf') > -1 ? 'file' : 'picture',
        data: url,
        name: decodeURI(extractedName),
      };
    });
    setImages(items);
  }, [form, uploads, id]);

  const handleImageOpen = index => {
    setPreview(index);
    console.log('index', index);
  };

  const handleImageClose = () => {
    setPreview(null);
  };

  const handleDelete = i => {
    setPreview(null);
    const deleteFileName = images[i].name;
    const formValues = images.map((item, index) => ({ id: index, fileName: item.name })).filter(item => item.id !== i);

    client
      .mutate({
        mutation: removeUploadedForm,
        variables: { id, procedureId, fileName: deleteFileName, value: JSON.stringify(formValues) },
        refetchQueries: [{ query: uploadFormTypesForProcedurePreOp, variables: { id, procedureId } }],
      })
      .then(result => {
        if (result.data) {
          setImages([...images.slice(0, i), ...images.slice(i + 1)]);
        } else {
          console.warn('Error deleting file:', deleteFileName);
        }
      })
      .catch(reason => alert(reason));
  };

  const handleDeleteConfirm = () => {
    setConfirmationOpen(true);
  };

  const handleFilePickerChange = file => {
    if (!file) return;

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = e => {
      const data = e.target.result;
      setImages([
        ...images,
        {
          type: file.type.indexOf('pdf') > -1 ? 'file' : 'picture',
          data: data,
          name: file.name + '_' + images.length,
        },
      ]);
      processUpload(images, data, file.name, file.type);
    };
  };

  const handleFilePickerError = error => {
    alert(error);
  };

  const processUpload = async (existingItems, newItem, fileName, contentType) => {
    try {
      const newFileName = fileName + '_' + existingItems.length;
      const preSignQueryResult = await client.query({
        query: uploadRequest,
        variables: { id, procedureId, fileName: newFileName, contentType },
      });

      const preSignedUrl = preSignQueryResult.data.uploadRequest;
      if (!Boolean(preSignedUrl)) {
        console.error('PreSign URL invalid');
        return;
      }
      console.log('File upload url', preSignedUrl);

      const formValues = existingItems.map((item, index) => ({ id: index, fileName: item.name }));
      formValues.push({ id: existingItems.length, fileName: newFileName });

      console.log('Uploading...', formValues);
      setItemUploadings([...itemUploadings, existingItems.length]);
      try {
        await uploadFileToPreSignedUrl(preSignedUrl, newItem, contentType, fileName);
      } finally {
        setItemUploadings([...itemUploadings]);
      }

      const confirmUploadQueryResult = await client.mutate({
        mutation: confirmFormUpload,
        variables: { id, procedureId, fileName: newFileName, value: JSON.stringify(formValues) },
        refetchQueries: [{ query: uploadFormTypesForProcedurePreOp, variables: { id, procedureId } }],
      });

      if (!Boolean(confirmUploadQueryResult.data.confirmFormUpload)) {
        console.error('Error while confirming upload. Try reuploading!');
        return;
      }
      console.log('Upload confirmed!');
    } catch (e) {
      console.error('Error while processing upload', e);
    }
  };

  // if we do not have dataUri, we would set noConvert param
  const uploadFileToPreSignedUrl = async (url, file, contentType, name, noConvert) => {
    try {
      const headers = new Headers();
      headers.append('pragma', 'no-cache');
      headers.append('Content-Type', contentType);
      if (name && name.indexOf('.pdf') < 0) {
        headers.append('Content-Disposition', 'attachment; filename=' + name);
      }

      const response = await fetch(url, {
        method: 'PUT',
        body: noConvert ? file : convertDataURItoBlob(file),
        headers,
      });

      if (response.status === 200) {
        console.log('File uploaded to S3!');
      } else {
        console.warn('Error uploading file', response);
      }
    } catch (e) {
      console.error('Uploading error', e);
    }
  };

  const convertDataURItoBlob = dataURI => {
    let byteString;
    let mimeString;
    let ia;

    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = encodeURI(dataURI.split(',')[1]);
    }
    // split the mime component
    mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes to a typed array
    ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  };

  const handleThumbnailClick = url => {
    const thumbIndex = onlyImages().findIndex(item => item.url === url);
    if (thumbIndex > -1) {
      setLightboxIndex(thumbIndex);
    } else {
      setLightboxIndex(0);
    }
    setLightboxOpen(true);
  };

  const handleDeleteLightBox = () => {
    setLightboxOpen(false);
    setConfirmLightboxOpen(true);
  };

  const handleDeleteImageLightbox = () => {
    const lightboxImages = onlyImages();

    const urlToRemove = lightboxImages[lightboxIndex].url;
    const imageIndex = images.findIndex(item => item.data === urlToRemove);
    if (imageIndex > -1) {
      handleDelete(imageIndex);
    }
  };

  const handleDownloadLightBox = () => {
    const lightboxImages = onlyImages();
    let index = lightboxIndex;
    if (index < 0 || index > lightboxImages.length - 1) index = 0;

    const urlToDownload = lightboxImages[index].url;
    const link = document.createElement('a');
    link.download = 'Image' + lightboxImages[index].name;
    link.target = '_blank';
    link.href = urlToDownload;
    link.text = 'Image download';
    link.dispatchEvent(new MouseEvent('click'));
    URL.revokeObjectURL(link.href);
  };

  const handlePrintImage = () => {
    const lightboxImages = onlyImages();
    let index = lightboxIndex;
    if (index < 0 || index > lightboxImages.length - 1) index = 0;

    const urlToPrint = lightboxImages[index].url;

    const win = window.open();
    win.document.write(`<img style='max-width: 100%' src='${urlToPrint}' onload="window.print()" />`);
    win.focus();
  };

  const onlyImages = () =>
    images.filter(image => image.type === 'picture').map(image => ({ name: image.name, url: image.data }));

  const lightboxMainSrc = () => onlyImages()[lightboxIndex].url;

  const lightboxNextSrc = () => {
    const items = onlyImages();
    return items[(lightboxIndex + 1) % items.length].url;
  };

  const lightboxPrevSrc = () => {
    const items = onlyImages();
    return items[(lightboxIndex + items.length - 1) % items.length].url;
  };

  const lightboxMovePrev = () => {
    const items = onlyImages();
    setLightboxIndex((lightboxIndex + items.length - 1) % items.length);
  };

  const lightboxMoveNext = () => {
    setLightboxIndex((lightboxIndex + 1) % onlyImages().length);
  };

  return (
    <Paper elevation={0} className={classes.panel} style={{ flex: 1 }}>
      <Box pl={2} pr={1} py={1} display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Box mr={1} display="flex">
            <Icon color="disabled">{icon}</Icon>
          </Box>
          <Typography variant="subtitle2">{title}</Typography>
        </Box>

        <Hidden mdUp>
          <label htmlFor={'documentPhoto' + procedure + form}>
            <IconButton color="primary" component="span">
              <Icon>add_a_photo</Icon>
              <input
                type="file"
                id={'documentPhoto' + procedure + form}
                name={'documentPhoto' + procedure + form}
                accept="image/*"
                capture="environment"
                style={{ display: 'none' }}
                onChange={e => handleFilePickerChange(e.target.files ? e.target.files[0] : undefined)}
              />
            </IconButton>
          </label>
        </Hidden>

        <Hidden smDown>
          <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" edge="end" size="small">
            <FilePicker
              extensions={['jpg', 'jpeg', 'png', 'pdf']}
              maxSize={50}
              onChange={handleFilePickerChange}
              onError={handleFilePickerError}
            >
              <Icon color="primary">publish</Icon>
            </FilePicker>
          </IconButton>
        </Hidden>
      </Box>

      {lightboxOpen && (
        <Lightbox
          mainSrc={lightboxMainSrc()}
          nextSrc={lightboxNextSrc()}
          prevSrc={lightboxPrevSrc()}
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={lightboxMovePrev}
          onMoveNextRequest={lightboxMoveNext}
          toolbarButtons={[
            <IconButton className={classes.white} onClick={handleDownloadLightBox}>
              <Icon>arrow_downward</Icon>
            </IconButton>,
            <IconButton className={classes.white} onClick={handleDeleteLightBox}>
              <Icon>delete</Icon>
            </IconButton>,
            <IconButton className={classes.white} onClick={handlePrintImage}>
              <Icon>print</Icon>
            </IconButton>,
          ]}
        />
      )}

      <ConfirmationDialog
        open={confirmLightboxOpen}
        setOpen={setConfirmLightboxOpen}
        title={'Confirm action'}
        text={'Are you sure you want to delete this image?'}
        onConfirm={handleDeleteImageLightbox}
      />

      {images.length !== 0 ? (
        <div className={classes.root}>
          <GridList cols={isMd ? 4 : 6} cellHeight="auto" style={{ flex: 1 }}>
            {images.map((image, index) => (
              <GridListTile key={index}>
                <Box
                  display="flex"
                  alignItems="stretch"
                  style={image.type !== 'picture' ? { height: '100%' } : undefined}
                >
                  {itemUploadings.indexOf(index) > -1 ? <CircularProgressWithLabel /> : null}
                  {image.type === 'picture' ? (
                    <Image
                      key={index + formType + image.name}
                      src={image.data}
                      onClick={() => handleThumbnailClick(image.data)}
                      color={theme.palette.background.default}
                      loading={<CircularProgress size={16} />}
                      animationDuration={1500}
                      aspectRatio={1}
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      imageStyle={{
                        margin: 'auto',
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <ButtonBase
                      focusRipple
                      className={classes.pdfItem}
                      style={{ minHeight: isMobile ? '70px' : '100px' }}
                      onClick={() => handleImageOpen(index)}
                      target="_self"
                    >
                      <Tooltip title={image.name}>
                        <Icon color="primary" style={{ transform: 'scale(1.8)' }}>
                          picture_as_pdf
                        </Icon>
                      </Tooltip>
                      {!isMobile && (
                        <label className={classes.thumbLabel}>
                          {image.name.substring(0, image.name.lastIndexOf('_'))}
                        </label>
                      )}
                    </ButtonBase>
                  )}
                </Box>
              </GridListTile>
            ))}
          </GridList>
        </div>
      ) : (
        <Box pb={2} px={2} flex={1} textAlign="center" display="flex" alignItems="center" justifyContent="center">
          <Typography variant="body2" color="textSecondary" style={{ opacity: 0.35 }}>
            <FormattedMessage id="questionnaire.docs.empty" defaultMessage="No docs here." />
          </Typography>
        </Box>
      )}

      <Dialog
        fullScreen={images[preview] && images[preview].type === 'file'}
        open={preview !== null}
        onClose={handleImageClose}
        TransitionComponent={Transition}
      >
        {images[preview] ? (
          images[preview].type === 'picture' ? (
            <img src={images[preview].data} style={{ maxWidth: '100%' }} alt="preview" />
          ) : (
            <React.Fragment>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleImageClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  {images[preview].name.substring(0, images[preview].name.lastIndexOf('_'))}
                </Typography>
              </Toolbar>
              <iframe
                title={images[preview].name}
                src={images[preview].data}
                style={{ width: '100%', height: '100%' }}
                frameBorder={0}
                allowFullScreen={true}
              />
            </React.Fragment>
          )
        ) : null}

        <Button variant="contained" color="primary" onClick={handleDeleteConfirm}>
          {intl.formatMessage({ id: 'questionnaire.docs.delete', defaultMessage: 'Delete' })}
        </Button>
        <ConfirmationDialog
          open={confirmationOpen}
          setOpen={setConfirmationOpen}
          title={intl.formatMessage({ id: 'questionnaire.docs.confirm', defaultMessage: 'Confirm action' })}
          text={intl.formatMessage({
            id: 'questionnaire.docs.sure',
            defaultMessage: 'Are you sure you want to delete this file?',
          })}
          onConfirm={() => handleDelete(preview)}
        />
      </Dialog>
    </Paper>
  );
};

const DocumentUpload = ({ onDone, forms, procedureId }) => {
  const intl = useIntl();

  return (
    <div className="Page Disclaimer EvenPadding" style={{ justifyContent: 'flex-start' }}>
      <Typography variant="h5">
        <FormattedMessage id="questionnaire.docs.title" defaultMessage="Document Upload" />
      </Typography>
      <Box py={2}>
        <Grid container spacing={2}>
          {forms.map(item => (
            <Grid key={item.id} item xs={12} style={{ display: 'flex' }}>
              <Document
                title={intl.formatMessage({ id: item.name, defaultMessage: item.name })}
                icon={item.content}
                procedure={procedureId}
                form={item.id}
                formType={item.name}
                uploads={item.uploads || []}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Button color="primary" onClick={onDone}>
        {intl.formatMessage({ id: 'questionnaire.docs.finish', defaultMessage: 'Finish' })}
      </Button>
    </div>
  );
};

export default DocumentUpload;
