import gql from 'graphql-tag';
import { reviewIntegrationsFragments } from './rating';

export const hospital = gql`
  query patientHospital {
    patientHospital {
      id
      name
      address
      contact
      type
      reviewIntegrations {
        ...ReviewIntegrationsData
      }
      branding {
        careAppLogo
      }
      omitAbleToVisit
      showPostOp
    }
  }
  ${reviewIntegrationsFragments.all}
`;

export const patientSubscription = gql`
  subscription {
    familyMember {
      id
      name
      icon
      color
      physician
      events {
        admittedAt
        preOpAt
        orAt
        recoveryAt
        postOpAt
        ableToVisitPACUAt
        ableToVisitPOSTOPAt
        dischargedAt
      }
    }
  }
`;

export const procedure = gql`
  query patientProcedure($procedureId: Long) {
    patientProcedure(procedureId: $procedureId) {
      id
      organizationLocation {
        name
      }
    }
  }
`;

export const patient = gql`
  query patientForCaretaker {
    patientForCaretaker {
      id
      name
      initials
      caretakerMessages {
        phoneNumber
        content
      }
    }
  }
`;

const familyMember = {
  hospital,
  patientSubscription,
};

export default familyMember;
